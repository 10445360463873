import React, { useState, useEffect, useCallback } from 'react';
import { useParams, withRouter } from 'react-router-dom';
import { Row, Button, Card, Form, Modal } from 'react-bootstrap';
import { compose } from 'recompose';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faEdit } from '@fortawesome/free-solid-svg-icons';
import DashboardNav from '../dashboard';
import { withAPI } from '../../api';
import { withAuthorization } from '../../session';
import { ROUTES, ROLES, APPLICATION_STATUS } from '../../util';

const AdminViewHackerPage = (props) => {
    const { api } = props;
    const [app, setApp] = useState({});
    const [statusEdit, setStatusEdit] = useState(null);
    const [showAcceptanceStatusModal, setShowAcceptanceStatusModal] = useState(false);
    // Added to be able to edit the location hub
    const [showInfoModal, setShowInfoModal] = useState(false);
    const [locationEdit, setLocationEdit] = useState(null);

    const [error, setError] = useState(null);
    const { id } = useParams();
    const statusColourMap = status => {
        if (status === APPLICATION_STATUS.ACCEPTED_RSVP_YES || status === APPLICATION_STATUS.ACCEPTED_NEED_RSVP) {
          return 'text-success';
        }else if (status === APPLICATION_STATUS.SUBMITTED || status === APPLICATION_STATUS.WAITLISTED) {
            return 'text-warning';
        }else {
            return 'text-danger';
        }
    }
    const statusToText = status => {
        if (status === APPLICATION_STATUS.SUBMITTED) return "Under Review";
        if (status === APPLICATION_STATUS.NO_APP) return "Not Received";
        if (status === APPLICATION_STATUS.ACCEPTED_NEED_RSVP) return "Require RSVP";
        if (status === APPLICATION_STATUS.ACCEPTED_RSVP_YES) return "Accepted";
        if (status === APPLICATION_STATUS.ACCEPTED_RSVP_NO) return "RSVP'ed No";
        if (status === APPLICATION_STATUS.WAITLISTED) return "Waitlisted";
        if (status === APPLICATION_STATUS.ACCEPTED_OFFER_EXPIRED) return "Offer Expired";
        if (status === APPLICATION_STATUS.REJECTED) return "Rejected";
        return "Unknown"
    }

    const reviewEditHandler = event => {
        setError(null);
        setStatusEdit(app.acceptance_status);
        setShowAcceptanceStatusModal(true);
        event.preventDefault();
    };

    const reviewLocationEditHandler = event => {
        setError(null);
        setLocationEdit(app.location);
        setShowInfoModal(true);
        event.preventDefault();
    };

    const handleStatusSave = () => {
        const status = statusEdit;
        if ( !(status === "Accepted" || status === "Rejected" || status === "Waitlisted" )) {
            setError("Please select a valid option");
            return;
        }
        const statusMap = {
            "Accepted": APPLICATION_STATUS.ACCEPTED_NEED_RSVP,
            "Rejected": APPLICATION_STATUS.REJECTED,
            "Waitlisted": APPLICATION_STATUS.WAITLISTED,
        };
        api.updateApplicationById(id, JSON.stringify({status:statusMap[status]}))
        .then((res) => {
            setError("Success! ");
            setApp(res.Applicant);
        })
        .catch((error) => {
            setError(error.errorMsg);
        });
    }

    const handlelocationSave = () => {
        const location = locationEdit;
        api.updateApplicationById(id, JSON.stringify({location:location}))
        .then((res) => {
            setError("Success! ");
            setApp(res.Applicant);
        })
        .catch((error) => {
            setError(error.errorMsg);
        });
    }

    const handleDeleteApplicant = () => {
        api.deleteApplicationById(id)
        .then(() => {
            alert("Success! ");
        })
        .then(
            () => {window.location.reload();}
        )
        .catch((error) => {
            alert(error.errorMsg);
        });
        props.history.push(`${ROUTES.ADMIN_DASHBOARD_HACKER_INFO}`);
    }

    const onBackClick = () => {
        props.history.push(`${ROUTES.ADMIN_DASHBOARD_HACKER_INFO}`);
    }

    const getApp = useCallback(() => {
        api.getApplicationById(id)
        .then((res) => {
            setApp(res.Applicant);
        })
        .catch((error) => {
            setError(error.errorMsg);
        });
     }, [api, id]);

    useEffect(() => {
        getApp();
    }, [getApp]);

    return (
        <>
        <DashboardNav />
        <div className="gs-dashboard-content">
            {app != null &&
                <div className="gs-application-container p-3">
                <Row className="m-3">
                    <Button className="mr-3" onClick={onBackClick} ><FontAwesomeIcon icon={faArrowLeft}/></Button>
                    <h2><b>Applicant:</b> {app.first_name} {app.last_name}</h2>
                </Row>

                {/* Application Review */}
                <Card className="mt-4 mb-4">
                    <Card.Header>
                    <span className="float-right">
                        <Button variant="link" className="p-2 mx-1" onClick={reviewEditHandler}>
                        <FontAwesomeIcon icon={faEdit}/>
                        </Button>
                    </span>
                    Application Review
                    </Card.Header>
                    <Card.Body>
                        <p><b>Current Application Status:</b> <span className={statusColourMap(app.acceptance_status)}>{statusToText(app.acceptance_status)}</span></p>
                    </Card.Body>
                </Card>

                {/* Personal Information */}
                <Card className="mb-4">
                    <Card.Header>
                    Personal Information
                    <span className="float-right">
                        <Button variant="link" className="p-2 mx-1" onClick={reviewLocationEditHandler}>
                        <FontAwesomeIcon icon={faEdit}/>
                        </Button>
                    </span>
                    </Card.Header>
                    
                    <Card.Body>
                        {/* Updated FEB 2024 */}
                        <p><b>First Name:</b> {app.first_name}</p>
                        <p><b>Last Name:</b> {app.last_name}</p>
                        <p><b>Email:</b> {app.email}</p>
                        <p><b>Location:</b> {app.location}</p>
                        <p><b>University:</b> {app.university}</p>
                        <p><b>ID:</b> {app.year}</p>
                        <p><b>Time Zone:</b> {app.timezone}</p>
                        <p><b>Tag:</b> {app.tag}</p>
                        <p><b>Dietary Restrictions:</b> {app.tag}</p>
                        <p><b>Social URL:</b> {app.application_typeform_id}</p>
                    </Card.Body>
                </Card>

                {/* Team Information */}
                <Card className="mb-4">
                    <Card.Header>
                    Team Information
                    </Card.Header>
                    <Card.Body>
                    {
                        app.team? (
                        <>
                            <p><b>Team name:</b> {app.team.team_name}</p>
                            <p><b>Unique Team Code:</b> {app.team.team_code}</p>
                        </>
                        ) : "Applicant does not have a team"
                    }
                    </Card.Body>
                </Card>
                
                {/* Delete Applicant */}
                <Card className="mb-4">
                    <Card.Header className='text-danger font-weight-bold'>
                        -- Danger Zone --
                    </Card.Header>
                    <Card.Body>
                        <p>Are you sure you want to delete this applicant? This action cannot be undone.</p>
                        <Button onClick={handleDeleteApplicant}>Delete Applicant</Button>
                    </Card.Body>
                </Card>

                {/* Edit Modal */}
                <Modal centered show={showAcceptanceStatusModal} onHide={()=>{setShowAcceptanceStatusModal(false)}}>
                    <Modal.Header closeButton>
                        <Modal.Title>
                            {app.first_name} {app.last_name} Application Review
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form>
                            <Form.Group>
                            <Form.Label>Acceptance Status</Form.Label>
                            <Form.Control as="select" defaultValue="Select Option" onChange={e=>{setStatusEdit(e.target.value)}}>
                                <option disabled>Select Option</option>
                                <option>Accepted</option>
                                <option>Waitlisted</option>
                                <option>Rejected</option>
                            </Form.Control>
                            </Form.Group>
                        </Form>
                        {error && <p className="text-danger">{error}</p>}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button onClick={handleStatusSave}>Save</Button>
                    </Modal.Footer>
                </Modal>

                {/* Edit Hacker Info Modal (Not Sanitized, temporary) */}
                <Modal centered show={showInfoModal} onHide={()=>{setShowInfoModal(false)}}>
                    <Modal.Header closeButton>
                        <Modal.Title>
                            {app.first_name} {app.last_name}'s INFO (DANGER!!)
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form.Group>
                            <Form.Label>Location</Form.Label>
                            <Form.Control as="select" defaultValue={app.location} onChange={e=>{setLocationEdit(e.target.value)}}>
                                <option>OnlineGMT</option>
                                <option>OnlineEST</option>
                                <option>London</option>
                                <option>Toronto</option>
                            </Form.Control>
                        </Form.Group>

                        {error && <p className="text-danger">{error}</p>}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button onClick={handlelocationSave}>Save</Button>
                    </Modal.Footer>
                </Modal>


                </div>
            }
        </div>
        </>
    )
};

const condition = authUser => !!authUser && authUser.role === ROLES.ADMIN;
export default compose(withRouter, withAPI, withAuthorization(condition))(AdminViewHackerPage);
