export const EVENTS = {

    /************************************ General ********************************** */
    PAGE_VIEW: "Page Viewed",

    /************************** Registration + Sign in Flow ************************ */
    LOGIN: "Login",
    LOGIN_FAILURE: "Failed Login",
    LOGOUT: "Logout",
    REGISTER: "Registration",
    REGISTER_FAILURE: "Failed Registration",
    NOTIFICATION_SIGNUP: "Signup for notifications",

    /************************* Dashboard Team (no team) **************************** */
    CREATE_TEAM_BEGIN: "CreateTeamBegin",
    // CREATE_TEAM_CANCEL: "CreateTeamCancel",
    CREATE_TEAM_SUCCESS: "CreateTeamSuccess",
    JOIN_TEAM_BEGIN: "JoinTeamBegin",
    // JOIN_TEAM_CANCEL: "JoinTeamCancel",
    JOIN_TEAM_SUCCESS: "JoinTeamSuccess",

    /************************ Dashboard Team (in team) ****************************** */
    LEAVE_TEAM_BEGIN: "LeaveTeamBegin",
    // LEAVE_TEAM_CANCEL: "LeaveTeamCancel",
    LEAVE_TEAM_SUCCESS: "LeaveTeamSuccess",
    SUBMIT_BEGIN: "SubmitBegin",
    SUBMIT_SUCCESS: "SubmitSuccess",
    SELECT_THEME_BEGIN: "SelectThemeBegin",
    // SELECT_THEME_CANCEL: "SelectThemeCancel",
    SELECT_THEME_SUCCESS: "SelectThemeSuccess",
    JOIN_SLACK: "JoinSlackLinkClick",

}


// old page view naming convention (deprecated since Feb)
/*
const PAGES = {
    HOME: "Home Page",
    LOGIN: "Login Page",
    REGISTRATION: "Registration Page",
    DASHBOARD_HOME: "Dashboard",
    DASHBOARD_ACCOUNT: "Account Page"
}
*/

// see all page routes in /src/util/index.js
const PAGES = {
    HOME: "Home",
    LOGIN: "Login",
    REGISTRATION: "Registration",
    DASHBOARD_HOME: "Dashboard Home",
    DASHBOARD_TEAM: "Dashboard Team",
    DASHBOARD_SCHEUDLE: "Dashboard Schedule",
    DASHBOARD_WORKSHOP: "Dashboard Workshop",
    DASHBOARD_RESOURCES: "Dashboard Resources",
    DASHBOARD_ACCOUNT: "Dashboard Account",
    DASHBOARD_HACKERS: "Dashboard Hackers"
}

export const ROUTE_PAGE_MAPPINGS = {
    "/login": PAGES.LOGIN,
    "/register": PAGES.REGISTRATION,
    "/dashboard/home": PAGES.DASHBOARD_HOME,
    "/dashboard/team": PAGES.DASHBOARD_TEAM,
    "/dashboard/schedule": PAGES.DASHBOARD_SCHEUDLE,
    "/dasbhoard/workshop": PAGES.DASHBOARD_WORKSHOP,

    /* Dashboard Account pageview will be contanimated with admin data since both shares the same path to my account */
    "/dashboard/account": PAGES.DASHBOARD_ACCOUNT,
    "/dashboard/resources": PAGES.DASHBOARD_RESOURCES,
    "/dashboard/hackers": PAGES.DASHBOARD_HACKERS
}