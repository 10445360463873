import { API_BASE_URL } from '../config';

const API_BASE_URL_USER = API_BASE_URL + '/user';
const API_BASE_URL_HACKER = API_BASE_URL + '/hacker';
const API_BASE_URL_ADMIN = API_BASE_URL + '/admin';
const HEADERS =  {
    'Content-Type': 'application/json',
};

const toJson = response => response.json();

const handleErrors = (response) => {
    if (response.status !== 200) throw response;
    return response;
};

class API {
  currentUser = () =>
    fetch(API_BASE_URL_USER + "/current", {
      method: "GET",
      credentials: "include",
    })
      .then(toJson)
      .then(handleErrors);

  register = (email, password, first_name, last_name, acceptance_status, hub, adminCode) =>
    fetch(API_BASE_URL_USER + "/register", {
      method: "POST",
      headers: HEADERS,
      body: JSON.stringify({
        email,
        password,
        first_name,
        last_name,
        acceptance_status,
        hub,
        adminCode,
      }),
    })
      .then(toJson)
      .then(handleErrors);

  login = (email, password) =>
    fetch(API_BASE_URL_USER + "/login", {
      method: "POST",
      credentials: "include",
      headers: HEADERS,
      body: JSON.stringify({
        email,
        password,
      }),
    })
      .then(toJson)
      .then(handleErrors);

  logout = () =>
    fetch(API_BASE_URL_USER + "/logout", {
      method: "POST",
      credentials: "include",
    })
      .then(toJson)
      .then(handleErrors);

  sendResetPasswordEmail = (email) =>
    fetch(API_BASE_URL_USER + "/forgot-password", {
      method: "POST",
      headers: HEADERS,
      body: JSON.stringify({ request_email: email }),
    })
      .then(toJson)
      .then(handleErrors);

  resetPassword = (password, key) =>
    fetch(API_BASE_URL_USER + `/reset-password/${key}`, {
      method: "POST",
      headers: HEADERS,
      body: JSON.stringify({ new_password: password }),
    })
      .then(toJson)
      .then(handleErrors);

  sendVerificationEmail = (email) =>
    fetch(API_BASE_URL_USER + "/send-email-verification", {
      method: "POST",
      headers: HEADERS,
      body: JSON.stringify({ email }),
    })
      .then(toJson)
      .then(handleErrors);

  verifyEmail = (key) =>
    fetch(API_BASE_URL_USER + `/verify-email/${key}`, {
      method: "POST",
      headers: HEADERS,
      body: JSON.stringify({}),
    })
      .then(toJson)
      .then(handleErrors);

  updateUser = (body) =>
    fetch(API_BASE_URL_USER + "/update-user", {
      method: "POST",
      headers: HEADERS,
      credentials: "include",
      body: JSON.stringify(body),
    })
      .then(toJson)
      .then(handleErrors);
    
  getUsers = () =>
    fetch(API_BASE_URL_USER + "/get-users", {
        method: "GET",
        credentials: "include",
    })
      .then(toJson)
      .then(handleErrors);

  hackerGetTeam = () =>
    fetch(API_BASE_URL_HACKER + "/get-team", {
      method: "GET",
      credentials: "include",
    })
      .then(toJson)
      .then(handleErrors);

  // Should be refactored in future
  getTypeformSubmissionFile = (pitch_deck_link) =>
    fetch(
      API_BASE_URL_HACKER +
        "/get-submission?pitch_deck_link=" +
        encodeURIComponent(pitch_deck_link),
      {
        method: "GET",
        credentials: "include",
      }
    ).then((data) => data.blob());

  createTeam = (team_name) =>
    fetch(API_BASE_URL_HACKER + "/create-team", {
      method: "POST",
      headers: HEADERS,
      credentials: "include",
      body: JSON.stringify({ team_name }),
    })
      .then(toJson)
      .then(handleErrors);

  joinTeam = (team_code) =>
    fetch(API_BASE_URL_HACKER + "/join-team", {
      method: "POST",
      headers: HEADERS,
      credentials: "include",
      body: JSON.stringify({ team_code }),
    })
      .then(toJson)
      .then(handleErrors);

  leaveTeam = () =>
    fetch(API_BASE_URL_HACKER + "/leave-team", {
      method: "POST",
      headers: HEADERS,
      credentials: "include",
      body: JSON.stringify({}),
    })
      .then(toJson)
      .then(handleErrors);

  updateTeam = (body) =>
    fetch(API_BASE_URL_HACKER + "/update-team", {
      method: "POST",
      headers: HEADERS,
      credentials: "include",
      body: JSON.stringify(body),
    })
      .then(toJson)
      .then(handleErrors);

  // ******************* Admin API Calls *********************************
  getApplications = (year = undefined) =>
    fetch(
      API_BASE_URL_ADMIN + "/get-applications" + (year ? "?year=" + year : ""),
      {
        method: "GET",
        credentials: "include",
      }
    )
      .then(toJson)
      .then(handleErrors);

  getTeams = (year = undefined) =>
    fetch(API_BASE_URL_ADMIN + "/get-teams" + (year ? "&year=" + year : ""), {
      method: "GET",
      credentials: "include",
    })
      .then(toJson)
      .then(handleErrors);

  getPieData = (group, year = undefined) =>
    fetch(
      API_BASE_URL_ADMIN +
        "/get-pie-data?group=" +
        group +
        (year ? "&year=" + year : ""),
      {
        method: "GET",
        credentials: "include",
      }
    )
      .then(toJson)
      .then(handleErrors);

  getProgressData = (year = undefined) =>
    fetch(
      API_BASE_URL_ADMIN + "/get-progress-data" + (year ? "?year=" + year : ""),
      {
        method: "GET",
        credentials: "include",
      }
    )
      .then(toJson)
      .then(handleErrors);

  getApplicationById = (id) =>
    fetch(API_BASE_URL_ADMIN + "/get-application-by-id?id=" + id, {
      method: "GET",
      credentials: "include",
    })
      .then(toJson)
      .then(handleErrors);

  updateApplicationById = (id, key_val) =>
    fetch(API_BASE_URL_ADMIN + "/update-application-by-id", {
      method: "POST",
      headers: HEADERS,
      credentials: "include",
      body: JSON.stringify({ id, key_val }),
    })
      .then(toJson)
      .then(handleErrors);

  updateApplicantionStatuses = (csvData) =>
    fetch(API_BASE_URL_ADMIN + "/update-application-statuses", {
      method: "POST",
      headers: HEADERS,
      credentials: "include",
      body: JSON.stringify({ csvData }),
    })
      .then(toJson)
      .then(handleErrors);

  deleteApplicationById = (id) =>
    fetch(API_BASE_URL_ADMIN + "/delete-application-by-id", {
      method: "POST",
      headers: HEADERS,
      credentials: "include",
      body: JSON.stringify({ id }),
    })
      .then(toJson)
      .then(handleErrors);

 generateTeamCode = () =>
  fetch(API_BASE_URL_ADMIN + "/generate-team-code", {
    method: "POST",
    headers: HEADERS,
    credentials: "include",
  })
    .then(toJson)
    .then(handleErrors);

  backendTeamFormation = () =>
  fetch(API_BASE_URL_ADMIN + "/backend-team-formation", {
    method: "POST",
    headers: HEADERS,
    credentials: "include",
  })
    .then(toJson)
    .then(handleErrors);

  change_user_team = (user_email, team_code) => 
  fetch(API_BASE_URL_ADMIN + "/change-user-team", {
    method: "POST",
    headers: HEADERS,
    credentials: "include",
    body: JSON.stringify({ user_email, team_code })
  })
    .then(toJson)
    .then(handleErrors);
};

export default API;