/* 
Features: 
* search bar
* view all applicants
* view and edit a given applicants application status (TODO: get typeform response for all data including resume)
* view all teams
* view and edit specific team (link to typeform responses)
*/
//TODO: make teams page

import React, { useState, useEffect } from 'react';
import { Button, Form } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { withAPI } from '../../api';
import { ROLES, APPLICATION_STATUS, ROUTES } from '../../util';
import { withAuthorization } from '../../session';
import DashboardNav from '../dashboard';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import ToolkitProvider, { CSVExport, Search } from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import YearSelectDropDown from '../../components/YearSelectDropDown';

const { SearchBar } = Search;

const { ExportCSVButton } = CSVExport;

const AdminHackerInfoPage = (props) => {
    const { api } = props;
    const [apps, setApps] = useState([]);
    const [teams, setTeams] = useState([]);
    const [view, setView] = useState("Applications");  // Views: Applications / Teams
    const [error, setError] = useState(null);
    const [currentYear, setCurrentYear] = useState(new Date().getFullYear());

    // For the team change functionality
    const [user, setUser] = useState("");
    const [dest_team, setdest_team] = useState("");

    useEffect(() => {
        const getApps = () => {
            let appsData = [];
            api.getApplications(undefined)
            .then((res) => {
                const Applicants = res.Applicants;
                for (let Applicant of Applicants) {
                    const applicant = {};
                    applicant['email'] = Applicant['email'];
                    applicant['status'] = statusToText(Applicant['acceptance_status']);
                    applicant['name'] = `${Applicant['first_name']} ${Applicant['last_name']}`;
                    applicant['team_name'] = Applicant.team ? Applicant.team.team_name: '';
                    applicant['team_code'] = Applicant.team ? Applicant.team.team_code: '';
                    applicant['HTG_GIVEN_id'] =  Applicant['year'];
                    // Applicant
                    applicant['tag'] =  Applicant['tag'];
                    applicant['id'] =  Applicant['_id'];
                    applicant['dietary_restrictions'] = Applicant['rsvp_typeform_id'];
                    appsData.push(applicant);
                }
                setApps(appsData);
            })
            .catch((error) => {
                setError(error.errorMsg);
            });
        };
        const getTeams = () => {
            let teamsData = [];
            api
              .getTeams()
              .then((res) => {
                console.log(res)
                const Teams = res.Teams;
                for (let Team of Teams) {
                  const team = {};
                  team["team_name"] = Team["team_name"];
                  team["team_code"] = Team["team_code"];
                  team["theme"] = themeToText(Team["theme"]);
                  team["pitch_deck_link"] = Team["pitch_deck_link"];
                  team["submission_repo_link"] = Team["submission_repo_link"];
                  team["prerecording_link"] = Team["prerecording_link"];
                  team["location"] = Team["team_location"]
                  team["team_members"] = Team.team_members
                    .map(
                      (member) => `${member.first_name} ${member.last_name}`
                    )
                    .join(", ");
                  team["id"] = Team["_id"];
                  teamsData.push(team);
                }
                setTeams(teamsData);
              })
              .catch((error) => {
                setError(error.errorMsg);
              });
        };
        getApps();
        getTeams();
        
    }, [api, currentYear]);

    const handleYearChange = (e) => {
        setCurrentYear(e.currentTarget.value);
    }

    const statusToText = status => {
        if (status === APPLICATION_STATUS.SUBMITTED) return "Under Review";
        if (status === APPLICATION_STATUS.NO_APP) return "Not Received";
        if (status === APPLICATION_STATUS.ACCEPTED_NEED_RSVP) return "Require RSVP";
        if (status === APPLICATION_STATUS.ACCEPTED_RSVP_YES) return "Accepted";
        if (status === APPLICATION_STATUS.ACCEPTED_RSVP_NO) return "RSVP'ed No";
        if (status === APPLICATION_STATUS.WAITLISTED) return "Waitlisted";
        if (status === APPLICATION_STATUS.ACCEPTED_OFFER_EXPIRED) return "Offer Expired";
        if (status === APPLICATION_STATUS.REJECTED) return "Rejected";
        return "Unknown"
    }

    function statusFormatter(cell, row) {
        if (row.status === statusToText(APPLICATION_STATUS.ACCEPTED_RSVP_YES) || row.status === statusToText(APPLICATION_STATUS.ACCEPTED_NEED_RSVP)) {
          return (<span className='text-success'>{cell}</span>);
        }else if (row.status === statusToText(APPLICATION_STATUS.SUBMITTED) || row.status === statusToText(APPLICATION_STATUS.WAITLISTED)) {
            return (<span className='text-warning'>{cell}</span>);
        }else {
            return (<span className='text-danger'>{cell}</span>);
        }
    }

    const customTotal = (from, to, size) => (
        <span className="react-bootstrap-table-pagination-total">
        Showing { from } to { to } of { size } Results
        </span>
    );

    const appRowEvents = {
        onClick: (e, row, rowIndex) => {
          props.history.push(`${ROUTES.ADMIN_DASHBOARD_HACKER_INFO}/${row.id}`);
        },
    };

    const appColumns = [
        { text: 'Name', dataField: 'name'},
        { text: 'Status', dataField: 'status', formatter: statusFormatter},
        { text: 'Email', dataField: 'email'},
        { text: 'Team Name', dataField: 'team_name', hidden: true},
        { text: 'Team Code', dataField: 'team_code', hidden: true},
        { text: 'ID', dataField: 'HTG_GIVEN_id'},
        { text: 'Role', dataField: 'tag'},
        { text: 'Dietary Restrictions', dataField: 'dietary_restrictions'},
    ]
    
    const appOptions = {
        paginationSize: 4,
        pageStartIndex: 0,
        showTotal: true,
        paginationTotalRenderer: customTotal,
        disablePageTitle: true,
        sizePerPageList: [{
            text: '20', value: 20
            }, {
            text: '50', value: 50
            }, {
            text: '100', value: 100
            }, {
            text: '500', value: 500
            }, {
            text: 'All', value: apps.length
        }] 
    };

    const teamRowEvents = {
        onClick: (e, row, rowIndex) => {
          console.log(`clicked on row with index: ${row.id}`);
        //   props.history.push(`${ROUTES.ADMIN_DASHBOARD_HACKER_INFO}/${row.id}`);
        },
    };


    // Map to new themes - temporary
    const themeToText = theme => {
        if (theme === "Economic Advancement") return "Economic Empowerment";
        if (theme === "Sustainable Societies") return "Sustainable Infrastructure";
        if (theme === "Health & Humanity") return "Health & Humanity";
        return "Unselected"
    }

    const teamColumns = [
        { text: 'Team', dataField: 'team_name'},
        { text: 'Code', dataField: 'team_code', hidden: true},
        { text: 'Theme', dataField: 'theme'},
        { text: 'Members', dataField: 'team_members'},
        { text: 'Location', dataField: 'location'},
    ]
    
    const teamOptions = {
        paginationSize: 4,
        pageStartIndex: 0,
        showTotal: true,
        paginationTotalRenderer: customTotal,
        disablePageTitle: true,
        sizePerPageList: [{
            text: '20', value: 20
            }, {
            text: '50', value: 50
            }, {
            text: 'All', value: apps.length
        }] 
    };



    // Get a random team code and push it as a popup
    const handleGetTeamCode = () => {
        api.generateTeamCode()
        .then((res) => {
            window.alert(res.code);
        })
        .catch((error) => {
            console.log(error);
            });
    }

    // Temporary to handle Team formation from the CSV, housed and uploaded in the backend
    const handleBackendCSVTeamFormation = () => {
        var result = window.confirm("Are you sure you want to proceed? Note that currently, the CSV must be populated from the backend. This will change everyone's teams!");
        if (result) {
            api.backendTeamFormation()
            .then((res) => {
                console.log(res.status);
            })
            .catch((error) => {
                console.log(error);
              });
        };
        
    }

    const handleTeamChanger = () => {
        var result = window.confirm("Are you sure you want to proceed? Changing " + user + " to team code " + dest_team);
        if (result){
            api.change_user_team(user, dest_team)
            .then((res) => {
                console.log(res.status);
                if (res.status === 200){
                    window.alert("Success!");
                }
            })
            .catch((error) => {
                console.log(error);
              });
        }
    }

    const handleuserEmailChange = (event) => {
        setUser(event.target.value);
      };
    
    const handleDestTeamChange = (event) => {
        setdest_team(event.target.value);
    };

    return (
        <>
            <DashboardNav />
            <div className="gs-dashboard-content">
                <div className='d-flex justify-content-end'>
                    <Button className={view==="Applications"? "mb-3 mr-3" : "mb-3 mr-3 btn-secondary"} onClick={() => setView("Applications")}> Applications </Button>
                    <Button className={view==="Teams"? "mb-3 mr-3" : "mb-3 mr-3 btn-secondary"} onClick={() => setView("Teams")}> Teams </Button>
                </div>
                { error && <p className='text-red'> {error} </p>}
                {view === "Applications" && 
                    <div className="gs-applications-container w-100 p-3">
                        <ToolkitProvider keyField='id' data={ apps } columns={ appColumns } search exportCSV>
                            {props => (
                                <div>
                                    <ExportCSVButton { ...props.csvProps } style={{color: 'white'}}>Download CSV</ExportCSVButton>
                                    <br />
                                    <br />
                                    <span>
                                    <Form class = 'row g-3'>
                                        <Form.Control value = {user} type ="text" placeholder="User Email" onChange={handleuserEmailChange}/>
                                        <Form.Control value = {dest_team} type="text" placeholder="Destination Team Code" onChange={handleDestTeamChange}/>
                                        <Button onClick={handleTeamChanger}>Change User Team</Button>
                                    </Form>
                                    </span>
                                    <br />
                                    <br />
                                    {/* <YearSelectDropDown handleOnChange={handleYearChange} /> */}
                                    <br />
                                    <SearchBar { ...props.searchProps } placeholder="Search Anything" />
                                    <br />
                                    <BootstrapTable 
                                        { ...props.baseProps } 
                                        pagination={ paginationFactory(appOptions) } 
                                        rowEvents={ appRowEvents }
                                        hover/>
                                </div>
                            )}
                        </ToolkitProvider>
                    </div>
                }
                {view === "Teams" && 
                    <div className="gs-applications-container w-100 p-3">
                        <ToolkitProvider keyField='id' data={ teams } columns={ teamColumns } search exportCSV>
                            {props => (
                                <div>
                                    <ExportCSVButton { ...props.csvProps } style={{color: 'white'}}>Download CSV</ExportCSVButton>
                                    <br />
                                    <br />
                                    <Button onClick={handleBackendCSVTeamFormation}>Handle Team Formation with Backend CSV</Button>
                                    <br />
                                    <br />
                                    <Button onClick={handleGetTeamCode}>Generate a Unique Team Code</Button>
                                    <br />
                                    <br />
                                    <SearchBar { ...props.searchProps } placeholder="Search Anything" />
                                    <br />
                                    <BootstrapTable 
                                        { ...props.baseProps } 
                                        pagination={ paginationFactory(teamOptions) } 
                                        rowEvents={ teamRowEvents }
                                        hover/>
                                </div>
                            )}
                        </ToolkitProvider>
                        
                    </div>
                }
            </div>
        </>
    )
};

const condition = authUser => !!authUser && authUser.role === ROLES.ADMIN;
export default compose(withRouter, withAPI, withAuthorization(condition))(AdminHackerInfoPage);
