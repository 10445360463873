import React from 'react';
import { useState, useEffect } from 'react';
import { Card, Col, Container, Image, Row } from 'react-bootstrap';
import { withRouter } from 'react-router';
import { compose } from 'recompose';

import { PAGES_IMAGES, HACKER_LINKS } from '../../../util';
import Footer from '../../../components/Footer';
import DashboardNav from '../';
import { withAuthorization } from '../../../session';
import { withAPI } from '../../../api';


const Resource = ({ title, description, link_name }) => {
    return (
        <>
            <Col md={6}>
                <Card className="mr-3 display-flex h-100">
                    <Row className="d-flex align-items-center">
                        <Col md={2}>
                            <Image src={PAGES_IMAGES.resources_lightbulb} fluid className="resources-lightbulb rounded-0" />
                        </Col>
                        <Col md={6}>
                            <h6 className="mt-4 font-weight-bold">{title}</h6>
                            <p>{description}</p>
                        </Col>
                        <Col md={3}>
                            <a href={link_name} target="_blank" rel="noopener noreferrer" className="view-button w-100">
                                <small>View</small>
                            </a>
                        </Col>
                    </Row>
                </Card>
            </Col>
        </>
    )
}

/* Join Slack Section - removed for now
const JoinSlack = ({ title, description, link_name }) => {
    return (
        <>
            <Col md={12}>
                <Card className="mr-3 display-flex h-100">
                    <Row className="d-flex align-items-center">
                        <Col md={1}>
                        <Image src={PAGES_IMAGES.slack_png} fluid className="slack-img resources-lightbulb rounded-0" />
                        </Col>
                        <Col md={8}>
                            <h6 className="mt-4 font-weight-bold">{title}</h6>
                                <p>{description}</p>
                        </Col>
                        <Col md={3} className="join-slack">
                            <a href={link_name} target="_blank" rel="noopener noreferrer" className="view-button w-100">
                                <small>Join</small>
                            </a>
                        </Col>
                    </Row>
                </Card>
            </Col>
        </>
    )
}
*/

const ResourcePage = (props) => {
    const [location, setLocation] = useState('');

    useEffect(() => {
        props.api.currentUser().then((res) => {
            setLocation(res.User.location);
        });
    }, [props.api]);

    return (
        <>
            <DashboardNav />
            <div className="gs-dashboard-content">
                <div className="gs-resources-container">
                    <Container fluid className="gs-htg-block">

                        { /* Join Slack Resource Section
                        <h2 className="font-weight-bold">Stay Connected!</h2>
                        <Row className="get-connected">
                            <JoinSlack title="" description="Join the Slack for useful updates, to connect with hackers, and ask questions!" link_name={HACKER_LINKS.join_slack_2024} />
                        </Row> 
                        */ }

                        <h2 className="font-weight-bold">Resources</h2>
                        <p className="pb-3 resources-card-text">The essential resources you need for Hack the Globe! </p>
                        {(location === "Toronto") &&
                            <>
                                <Row className="pb-3">
                                    <Resource title="Toronto" description="2024 Hacker Guide" link_name={HACKER_LINKS.hacker_guide_toronto2024} />
                                    <Resource title="Toronto" description="2024 Workshops / OH Schedule" link_name={HACKER_LINKS.workshops_toronto2024} />
                                </Row>
                                <Row>
                                    <Resource title="Toronto" description="2024 In Person Logistics" link_name={HACKER_LINKS.logistics_toronto2024} />
                                    <Resource title="Toronto" description="2024 Hack the Globe Recordings" link_name={HACKER_LINKS.htg_recordings_2024} />
                                </Row>
                            </>
                        }
                        {(location === "London") &&
                            <>
                                <Row className="pb-3">
                                    <Resource title="London" description="2024 Hacker Guide" link_name={HACKER_LINKS.hacker_guide_london2024} />
                                    <Resource title="London" description="2024 Workshops / OH Schedule" link_name={HACKER_LINKS.workshops_london2024} />
                                </Row>
                                <Row>
                                    <Resource title="London" description="2024 In Person Logistics" link_name={HACKER_LINKS.logistics_london2024} />
                                    <Resource title="London" description="2024 Hack the Globe Recordings" link_name={HACKER_LINKS.htg_recordings_2024} />
                                </Row>
                            </>
                        }
                        {(location === "OnlineEST") &&
                            <>
                                <Row className="pb-3">
                                    <Resource title="Online EST" description="2024 Hacker Guide" link_name={HACKER_LINKS.hacker_guide_onlineest2024} />
                                    <Resource title="Online EST" description="2024 Workshops / OH Schedule" link_name={HACKER_LINKS.workshops_onlineest2024} />
                                </Row>
                                <Row>
                                    <Resource title="Online EST" description="2024 Hack the Globe Recordings" link_name={HACKER_LINKS.htg_recordings_2024} />
                                </Row>
                            </>
                        }
                        {(location === "OnlineGMT") &&
                            <>
                                <Row className="pb-3">
                                    <Resource title="Online GMT" description="2024 Hacker Guide" link_name={HACKER_LINKS.hacker_guide_onlinegmt2024} />
                                    <Resource title="Online GMT" description="2024 Workshops / OH Schedule" link_name={HACKER_LINKS.workshops_onlinegmt2024} />
                                </Row>
                                <Row>
                                    <Resource title="Online GMT" description="2024 Hack the Globe Recordings" link_name={HACKER_LINKS.htg_recordings_2024} />
                                </Row>
                            </>
                        }

                    </Container>

                    <Container fluid className="gs-htg-block">
                        <h2 className="font-weight-bold">Check Out The Themes!</h2>
                        <p className="pb-3 resources-card-text">Three amazing themes lined up for Hack the Globe 2024 and resources for each</p>
                        <Row>
                            <Col xl={4} lg={6} className="pb-3">
                                <Card className="h-100">
                                    <Card.Img variant="top" src={PAGES_IMAGES.global.default} />
                                    <Card.Body>
                                        <Card.Title className="font-weight-bold" as="h4">Health and Humanity</Card.Title>
                                        <Card.Text className="resources-card-text">
                                            Ensuring everyone has access to clean water and sanitation, affordable healthcare, and appropriate food and nutrition - no matter their circumstances.
                                            <br />
                                            <br />
                                        </Card.Text>
                                        <Row className="mt-auto pb-3">
                                            {/* <Col>
                                                <a href={HACKER_LINKS.global_vid} target="_blank" rel="noopener noreferrer" className="btn-blue w-100">
                                                    <small>Theme Video</small>
                                                </a>
                                            </Col> */}
                                            <Col>
                                                <a href={HACKER_LINKS.health_guide2024} target="_blank" rel="noopener noreferrer" className="btn-blue w-100">
                                                    <small>Resources</small>
                                                </a>
                                            </Col>
                                        </Row>
                                        {/* removing theme partner
                                        <div className="gs-resource-theme-partners">
                                            <h5>
                                                Theme Partners:
                                            </h5>
                                            <div className="text-center">
                                                <Image src={SPONSOR_IMAGES.unicef} fluid />
                                                <br />
                                                <Image src={SPONSOR_IMAGES.wfp} fluid />
                                            </div>
                                        </div>*/}
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col xl={4} lg={6}>
                                <Card className="resources-col-height">
                                    <Card.Img variant="top" src={PAGES_IMAGES.equitable.default} />
                                    <Card.Body>
                                        <Card.Title className="font-weight-bold" as="h4">Economic Empowerment</Card.Title>
                                        <Card.Text className="resources-card-text">
                                            Building the capacity of underserved individuals and communities to gain equal access to education, economic opportunity, finance, and prosperity, upheld by respect for human dignity and rights.
                                            <br />
                                        </Card.Text>
                                        <Row className="mt-auto pb-3">
                                            {/* <Col>
                                                <a href={HACKER_LINKS.society_vid} target="_blank" rel="noopener noreferrer" className="btn-blue w-100">
                                                    <small>Theme Video</small>
                                                </a>
                                            </Col> */}
                                            <Col>
                                                <a href={HACKER_LINKS.economic_guide2024} target="_blank" rel="noopener noreferrer" className="btn-blue w-100">
                                                    <small>Resources</small>
                                                </a>
                                            </Col>
                                        </Row>
                                        {/* removing theme partners
                                        <div className="gs-resource-theme-partners">
                                            <h5>
                                                Theme Partners:
                                            </h5>
                                            <div className="text-center">
                                                <Image src={SPONSOR_IMAGES.stc} fluid />
                                                <br />
                                                <Image src={SPONSOR_IMAGES.agf} fluid />
                                                <br />
                                                <Image src={SPONSOR_IMAGES.bi} fluid /> 
                                                <br />
                                                <Image src={SPONSOR_IMAGES.civic} fluid /> 
                                            </div>
                                        </div>*/}
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col xl={4} lg={6} className="pb-3">
                                <Card className="h-100">
                                    <Card.Img variant="top" src={PAGES_IMAGES.climate.default} />
                                    <Card.Body>
                                        <Card.Title className="font-weight-bold" as="h4">Sustainable Infrastructure</Card.Title>
                                        <Card.Text className="resources-card-text">
                                            Accelerating progress on sustainable solutions to prevent further damage to our shared environment, and acting on the existing adverse effects of climate change.
                                            <br />
                                            <br />
                                        </Card.Text>
                                        <Row className="mt-auto pb-3">
                                            {/* <Col>
                                                <a href={HACKER_LINKS.climate_vid} target="_blank" rel="noopener noreferrer" className="btn-blue w-100">
                                                    <small>Theme Video</small>
                                                </a>
                                            </Col> */}
                                            <Col>
                                                <a href={HACKER_LINKS.sustainable_guide2024} target="_blank" rel="noopener noreferrer" className="btn-blue w-100">
                                                    <small>Resources</small>
                                                </a>
                                            </Col>
                                        </Row>
                                        {/* removing theme partners
                                        <div className="gs-resource-theme-partners">
                                            <h5>
                                                Theme Partners:
                                            </h5>
                                            <div className="text-center">
                                                <Image src={SPONSOR_IMAGES.soc} fluid />
                                                <br />
                                                <Image src={SPONSOR_IMAGES.dsf} fluid />
                                                <br />
                                                <Image src={SPONSOR_IMAGES.wwf} fluid />
                                                <br />
                                                <Image src={SPONSOR_IMAGES.be} fluid />
                                                <br />
                                                <Image src={SPONSOR_IMAGES.bcg} fluid />
                                            </div>
                                        </div> */}
                                    </Card.Body>
                                </Card>
                            </Col>
                            {/* <Col xl={3} lg={6}>
                            <Card className="h-100">
                                <Card.Img variant="top" src={PAGES_IMAGES.economic} />
                                <Card.Body>
                                    <Card.Title as="h4">Economic Empowerment</Card.Title>
                                    <Card.Text>
                                    Empower underrepresented populations to access education, increase economic opportunities, and realize decent work for all. 
                                    </Card.Text>
                                    <Row className="mt-auto pb-3">
                                        <Col>
                                            <a href={HACKER_LINKS.economic_vid} target="_blank" rel="noopener noreferrer" className="btn-blue w-100">
                                                <small>Theme Video</small>
                                            </a>
                                        </Col>
                                        <Col>
                                            <a href={HACKER_LINKS.economic_guide} target="_blank" rel="noopener noreferrer" className="btn-blue w-100">
                                                <small>Resources</small>
                                            </a>
                                        </Col>
                                    </Row>
                                    <div className="gs-resource-theme-partners">
                                        <h5>
                                            Theme Partners:
                                        </h5>
                                        <div className="text-center">
                                            <Image src={SPONSOR_IMAGES.ga} fluid />
                                            <br />
                                            <Image src={SPONSOR_IMAGES.agf} fluid />
                                            <br />
                                            <Image src={SPONSOR_IMAGES.rumie} fluid />
                                            <br />
                                            <Image src={SPONSOR_IMAGES.uncdf} fluid />
                                        </div>
                                    </div>
                                </Card.Body>
                            </Card>
                            </Col> */}
                        </Row>
                    </Container>

                    { /* Business Resources and Technical Resources 
                    <Container className="gs-htg-block">
                        <Row>
                            <Col md={6}>
                                <Card className="mr-md-3 mb-5 mb-md-0">
                                    <Card.Img variant="top" src={PAGES_IMAGES.business.default} />
                                    <Card.Body>
                                        <Card.Title as="h4">Business Resources</Card.Title>
                                        <Card.Text>
                                        A few resources to help you get started on your business plan and thinking about business strategy
                                        </Card.Text>
                                        <a href={HACKER_LINKS.business} target="_blank" rel="noopener noreferrer" className="btn-secondary w-50">
                                            <small>View</small>
                                        </a>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col md={6}>
                                <Card className="ml-md-3">
                                    <Card.Img variant="top" src={PAGES_IMAGES.technical.default} />
                                    <Card.Body>
                                        <Card.Title as="h4">Technical Resources</Card.Title>
                                        <Card.Text>
                                        Datasets, platforms, and tutorials to start building your technical solution
                                        </Card.Text>
                                        <a href={HACKER_LINKS.technical} target="_blank" rel="noopener noreferrer" className="btn-secondary w-50">
                                            <small>View</small>
                                        </a>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                        </Container> */}
                </div>
                <Footer />
            </div>
        </>
    )
};

const condition = authUser => !!authUser && ((authUser.acceptance_status === "ACCEPTED_RSVP_YES" && authUser.rsvp_option) || authUser.role === "Admin");
export default compose(compose(withRouter, withAuthorization(condition)), withAPI)(ResourcePage);
