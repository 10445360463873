import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { Button, Col, Container, Form, Image, Row } from 'react-bootstrap';

import { withAPI } from '../../api';
import { ROUTES, PAGES_IMAGES } from '../../util';
import Header from '../../components/Header';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import mixpanel from 'mixpanel-browser';
import {EVENTS} from '../../events/event_constants';

const LoginPage = () => (
    <div>
        <Container fluid>
            <Row className="h-100 mt-auto mb-auto">
                <Col md={5} xl={4}>
                    <Header />
                    <div className="gs-form-container pr-5 pl-5">
                        <h3 className="pb-3">Login to Dashboard</h3>
                        Don't Have An Account? <Link to={ROUTES.REGISTER}>Register Here</Link>
                        <LoginForm />
                    </div>
                </Col>
                <Col md={7} xl={8} className="bg-light-yellow h-100 p-0 d-none d-md-block">
                    <Image src={PAGES_IMAGES.login.default} className="gs-login-image" />
                </Col>
            </Row>
        </Container>
    </div>
);

const INITIAL_STATE = {
    email: '',
    password: '',
    error: null,
};

class LoginFormBase extends Component {
    constructor(props) {
        super(props);
        this.state = { ...INITIAL_STATE };
    }

    onSubmit = event => {
        event.preventDefault();
        this.refs.btn.setAttribute("disabled", "disabled");
        const { email, password } = this.state;
        
        this.props.api.login(email, password).then((data) => {
            this.setState({ ...INITIAL_STATE });
            mixpanel.track(EVENTS.LOGIN);
            data.isAdmin ? this.props.history.push(ROUTES.ADMIN_DASHBOARD_HOME) : this.props.history.push(ROUTES.DASHBOARD_HOME);
        }).catch(error => {
            this.refs.btn.removeAttribute("disabled");
            mixpanel.track(EVENTS.LOGIN_FAILURE, {
                'Reason': error.errorMsg
            });
            this.setState({ error });
        });
        /*this.props.firebase.doLoginWithEmailAndPassword(email, password).then(() => {
            this.setState({ ...INITIAL_STATE });
            this.props.firebase.analytics.logEvent('Successful Login');
            this.props.history.push(ROUTES.DASHBOARD);
        }).catch(error => {
            this.refs.btn.removeAttribute("disabled");
            this.props.firebase.analytics.logEvent(error && error.code ? error.code : 'Unsuccessful Login');
            this.setState({ error });
        });*/
    };

    onChange = event => {
        this.setState({ [event.target.name]: event.target.value });
    };

    render() {
        const { email, password, error } = this.state;

        const isInvalid = password === '' || email === '';

        return (
            <Form onSubmit={this.onSubmit}>
                <div className="pt-5">
                    <Form.Group>
                        <Form.Control name="email" value={email} onChange={this.onChange} type="email" placeholder="Email" />
                    </Form.Group>
                    <Form.Group controlId="loginForm.password">
                        <Form.Control name="password" value={password} onChange={this.onChange} type="password" placeholder="Password" />
                    </Form.Group>
                </div>
                {/* <div className="pb-2">
                    Don't Have An Account? <Link to={ROUTES.REGISTER}>Sign Up</Link>
                </div> */}
                <div className="pb-5">
                    <Link to={ROUTES.FORGET_PASSWORD}>Forgot Password</Link>
                </div>
                <Button ref="btn" className="mb-3" disabled={isInvalid} type="submit">
                    Let's Go <FontAwesomeIcon icon={faArrowRight}/>
                </Button>
                {error && <p className="text-danger">{error.errorMsg}</p>}
            </Form>
        );
    }
}

const LoginForm = compose(
    withRouter,
    withAPI,
)(LoginFormBase);

export default LoginPage;
