import { yearsList } from "../util/listYears";

export const YearSelectDropDown = ({ handleOnChange }) => {
    return (
        <>
        <label htmlFor="year-select">
                    Select HTG year:
                  </label>
                  <select
                    id="year-select"
                    name="htg_year"
                    defaultValue={new Date().getFullYear()}
                    onChange={handleOnChange}
                    className="mx-3"
                  >
                    {yearsList.map((year) => {
                      return (
                        <option key={year} value={year}>
                          {year}
                        </option>
                      );
                    })}
                    <option key="other" value={undefined}>
                      Previous years
                    </option>
                  </select>
        </>
    )
}

export default YearSelectDropDown;