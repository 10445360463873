/**
 * Return a boolean to indicate whether the time has passed the deadline
 * of a specified period.
 * @param {"APPLICATION_PERIOD" | "THEME_SELECTION_PERIOD" | "HACKATHON_PERIOD"} periodType - A period with start and end dates.
 * @param {number} dateTimeNow - The current date time as the number milliseconds since epoch obtained from Date.now().
 * @returns {boolean} a boolean value to indicate whether the time passed as an argument
 * has passed the deadline of the specified period.
 */
export const isDuringActivePeriod = (periodType, dateTimeNow) => {
    let startDateTime = 0;
    let closeDateTime = 0;

    switch (periodType) {
        case "APPLICATION_PERIOD":
            // EST UTC offset = -05:00
            startDateTime = Date.parse(process.env.REACT_APP_APP_STARTTIME);
            closeDateTime = Date.parse(process.env.REACT_APP_APP_CLOSETIME);
            break;
        case "TEAM_FORMATION_PERIOD":
            startDateTime = Date.parse("01 Jan 1970 00:00:00 GMT");
            closeDateTime = Date.parse("24 Feb 2024 12:00:00 GMT");
            break;
        case "THEME_SELECTION_PERIOD":
            startDateTime = Date.parse("01 Jan 1970 00:00:00 GMT");
            closeDateTime = Date.parse("15 Mar 2024 17:00:00 GMT");
            break;
        case "HACKATHON_PERIOD":
            startDateTime = Date.parse(process.env.REACT_APP_HACKATHON_STARTTIME);
            closeDateTime = Date.parse(process.env.REACT_APP_HACKATHON_CLOSETIME);
            break;
        default:
            startDateTime = Date.parse(process.env.REACT_APP_APP_STARTTIME);
            closeDateTime = Date.parse(process.env.REACT_APP_APP_CLOSETIME);
            break;
    }
    console.log("startDateTime:", startDateTime);
    console.log("closeDateTime:", closeDateTime);
    console.log("dateTimeNow:", dateTimeNow);
    const isActive = dateTimeNow >= startDateTime && dateTimeNow <= closeDateTime;
    // Temporarily SKIP checking the time period
    //const isActive = true;

    return isActive;
};
