import React from 'react';
import { withRouter, Route, Redirect, Switch } from 'react-router-dom';

import LoginPage from '../pages/login';
import RegisterPage from '../pages/register';
import EmailVerificationPage from '../pages/emailverification';
import ForgotPasswordPage from '../pages/forgotpassword';
import ForgotPasswordConfirmPage from '../pages/forgotpassword/forgotpasswordconfirm';
import HomePage from '../pages/dashboard/home';
import TeamPage from '../pages/dashboard/team';
// import SchedulePage from '../pages/dashboard/schedule3';
// import WorkshopsPage from '../pages/dashboard/workshops';
import AccountPage from '../pages/dashboard/account';
import ResourcesPage from '../pages/dashboard/resources';
import HackersPage from '../pages/dashboard/hackers';
import ResetPasswordPage from '../pages/resetpassword';
import ResetPasswordConfirmPage from '../pages/resetpassword/resetpasswordconfirm';
// import PreLaunchPage from '../pages/prelaunch';
import DoesNotExistPage from '../pages/404';
import AdminRegisterPage from '../pages/admin/register';
import AdminHomePage from '../pages/admin/dashboard';
import AdminHackerInfoPage from '../pages/admin/hackerInfos';
import AdminViewHackerPage from '../pages/admin/viewHacker';

import { ROUTES } from '../util';
import { AuthUserContext } from '../session';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { ROUTE_PAGE_MAPPINGS, EVENTS } from '../events/event_constants';
import mixpanel from 'mixpanel-browser';

const RouterItems = () => {
    const location = useLocation();
    const [prevLocation, setPrevLocation] = useState();

    //! Set debug to false before going into production
    mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN, {cross_subdomain_cookie: false, ignore_dnt: true, debug: false})

    useEffect(()=>{
        const currLocation = ROUTE_PAGE_MAPPINGS[location?.pathname];
        
        if (prevLocation !== currLocation) {
            mixpanel.track(EVENTS.PAGE_VIEW, {
                // if no mapping exists for currLocation (admin page), set Page=Admin which should be filtered out later when conducting analysis
                'Page': currLocation !== undefined ? currLocation : 'Admin'
            });
        }

        setPrevLocation(currLocation);
    }, [location.pathname, prevLocation])
    
    return (
        <AuthUserContext.Consumer>
        { ({authUser, updateAuthUser}) => authUser? 
            (authUser.isLoading? <></> : (<RouterItemsAuthed authUser={authUser} />)) 
            : 
            (<RouterItemsNonAuthed />) 
        }
    </AuthUserContext.Consumer>
    )
}

const RouterItemsAuthed = () => {
    return (
        <div>
            <Switch>
                <Route path={ROUTES.HOME} exact><Redirect to={ROUTES.DASHBOARD_HOME} /></Route>
                <Route path={ROUTES.LOGIN}><Redirect to={ROUTES.DASHBOARD_HOME} /></Route>
                <Route path={ROUTES.REGISTER}><Redirect to={ROUTES.DASHBOARD_HOME} /></Route>
                <Route path={ROUTES.DASHBOARD_HOME} component={HomePage} />
                <Route path={ROUTES.DASHBOARD_TEAM} component={TeamPage} />
                <Route path={ROUTES.DASHBOARD_ACCOUNT} component={AccountPage} />
                <Route path={ROUTES.DASHBOARD_RESOURCES} component={ResourcesPage} />
                <Route path={ROUTES.DASHBOARD_HACKERS} component={HackersPage} />
                {/* <Route path={ROUTES.DASHBOARD_WORKSHOP} component={WorkshopsPage} /> */}
                {/* <Route path={ROUTES.DASHBOARD_RESOURCES} component={ResourcesPage} /> */}
                <Route path={ROUTES.ADMIN_DASHBOARD_HOME} component={AdminHomePage} />
                <Route path={ROUTES.ADMIN_DASHBOARD_HACKER_INFO} component={AdminHackerInfoPage} exact/>
                <Route path={ROUTES.ADMIN_DASHBOARD_VIEW_HACKER} component={AdminViewHackerPage} />
                <Route component={DoesNotExistPage} />
            </Switch>
        </div>
    );
};

const RouterItemsNonAuthed = () => {
    return (
        <div>
            <Switch>
                {/* <Route path={ROUTES.HOME} exact component={PreLaunchPage} /> */}
                <Route path={ROUTES.HOME} exact><Redirect to={ROUTES.LOGIN} /></Route>
                <Route path={ROUTES.DASHBOARD}><Redirect to={ROUTES.LOGIN} /></Route>
                <Route path={ROUTES.LOGIN} component={LoginPage} />
                <Route path={ROUTES.REGISTER} component={RegisterPage} />
                <Route path={ROUTES.EMAIL_VERIFICATION} component = {EmailVerificationPage} />
                <Route path={ROUTES.FORGET_PASSWORD} component={ForgotPasswordPage} />
                <Route path={ROUTES.FORGET_PASSWORD_CONFIRM} component={ForgotPasswordConfirmPage} />
                <Route path={ROUTES.RESET_PASSWORD} component={ResetPasswordPage} />
                <Route path={ROUTES.RESET_PASSWORD_CONFIRM} component = {ResetPasswordConfirmPage} />
                <Route path={ROUTES.ADMIN_REGISTER} component={AdminRegisterPage} />
                <Route component={DoesNotExistPage} />
            </Switch>
        </div>
    );
};

export default withRouter(RouterItems);
