const env = process.env.REACT_APP_NODE_ENV || 'development';

// Setup non-secret environment configuration here, such as Base URL and Google user. 
function getConfig(environment) {
    if (environment === 'development') {
        return {
            API_BASE_URL: "http://localhost:5000"
        };
    }

    if (environment === 'development-heroku') {
        return {
            API_BASE_URL: "https://globalspark-hackerbackend-dev.herokuapp.com"
        };
    }

    if (environment === 'production') {
        return {
            API_BASE_URL: "https://hack.api.globalspark.world"
        };
    }
}

module.exports = getConfig(env);