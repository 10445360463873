//TODO: add deadline, change banner
import React, { Component } from 'react';
import { Button, Card, Col, Container, Image, Row, Modal, Form } from 'react-bootstrap';
import { compose } from 'recompose';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faDiceOne, faDiceTwo, faCloudUploadAlt, faEdit,
    faUser, faHandPeace, faBug, faBatteryFull, faBatteryEmpty,
    faEnvelope, faMapMarkerAlt, faMedal,
} from '@fortawesome/free-solid-svg-icons';

import { withAPI } from '../../../api';
import { AuthUserContext, withAuthorization } from '../../../session';
import { isDuringActivePeriod, PAGES_IMAGES, ROLES, APPLICATION_STATUS } from '../../../util';
import DashboardNav from '..';
import { EVENTS } from '../../../events/event_constants';
import mixpanel from 'mixpanel-browser';

const TeamPage = () => {
    return (
        <>
            <DashboardNav />
            <div className="gs-dashboard-content pt-0">
                <Container className="gs-team-container">
                    <Team />
                </Container>
            </div>
        </>
    )
};

const MemberCard = ({ self, profile_icon, profile_color, first_name, last_name, email, country, tag, setModalView }) => {
    return (
        <div className='gs-member-card'>
            <div className='d-flex justify-content-between'>
                <h5 style={{ color: profile_color ? PROFILE_COLORS[profile_color] : "#170B31" }}><FontAwesomeIcon size="lg" icon={profile_icon ? PROFILE_ICONS[profile_icon] : faUser} />{` `}{first_name}{` `}{last_name}</h5>
                {self && <FontAwesomeIcon onClick={() => { setModalView(EDIT_MEMBER_MODAL) }} icon={faEdit} />}
            </div>
            {email && <p><FontAwesomeIcon className='text-blue' icon={faEnvelope} />{` `}{email}</p>}
            {country && <p><FontAwesomeIcon className='text-red' icon={faMapMarkerAlt} />{` `}{country}</p>}
            {tag && <p><FontAwesomeIcon className='text-yellow' icon={faMedal} />{` `}{tag}</p>}
        </div>
    )
}

const CREATE_TEAM_MODAL = 0;
const JOIN_TEAM_MODAL = 1;
const LEAVE_TEAM_MODAL = 2;
const UPDATE_TEAM_NAME_MODAL = 3;
const EDIT_MEMBER_MODAL = 4;
const SUSTAINABILITY = "Sustainable Societies";
const ECONOMIC = "Economic Advancement";
const HEALTH = "Health & Humanity";
const PROFILE_ICONS = [faUser, faHandPeace, faBug, faBatteryFull, faBatteryEmpty];
const PROFILE_COLORS = ["#170B31", "#4542C2", "#36AB9D", "#1D65B1", "#F6BD42"];
const INITIAL_STATE = {
    // modals
    modalView: null,
    new_team_name: '',
    join_team_code: '',
    modalError: '',
    copied: false,
    // edit member info
    new_color: 0,
    new_icon: 0,
    new_country: '',
    new_tag: '',
    //edit theme
    isEditing: false,
    newTheme: null,
    themeError: null,
}

class TeamBase extends Component {
    constructor(props) {
        super(props);
        this.state = { ...INITIAL_STATE };
        this.setModalView = this.setModalView.bind(this);

        //! Set debug to false before going into production
        mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN, { cross_subdomain_cookie: false, ignore_dnt: true, debug: false })
    }

    componentDidMount() {
        this.refreshTeamInfo();
    }

    onChange = event => {
        this.setState({ [event.target.name]: event.target.value });
    };

    setModalView(view) {
        this.setState({ modalView: view });
        this.setState({ modalError: null });
    }

    refreshTeamInfo() {
        // update team members and self info
        this.props.api.hackerGetTeam().then((data) => {
            this.setState({ team: data.Team });
            if (data.Team) this.getSubmissionFile(data.Team.pitch_deck_link);
        }).catch(error => {
            console.log(error);
        });
    }

    getSubmissionFile(pitch_deck_link) {
        this.props.api.getTypeformSubmissionFile(pitch_deck_link)
            .then((pitch) => {
                const url = URL.createObjectURL(pitch);
                this.setState({ submissionFile: url });
            }).catch(error => {
                console.log(error);
            });
    }

    createNewTeam = (event, updateAuthUser) => {
        const { new_team_name } = this.state;
        this.props.api.createTeam(new_team_name).then(() => {
            this.refreshTeamInfo();
            updateAuthUser();
            this.setState({ modalView: null });

            mixpanel.track(EVENTS.CREATE_TEAM_SUCCESS);

        }).catch(error => {
            this.setState({ modalError: error.errorMsg });
        });
        event.preventDefault();
    }

    updateTeamName = (event, updateAuthUser) => {
        const { new_team_name } = this.state;
        this.props.api.updateTeam({ team_name: new_team_name }).then(() => {
            this.refreshTeamInfo();
            this.setState({ modalView: null });
        }).catch(error => {
            this.setState({ modalError: error.errorMsg });
        });
        event.preventDefault();
    }

    joinNewTeam = (event, updateAuthUser) => {
        const { join_team_code } = this.state;
        this.props.api.joinTeam(join_team_code).then(() => {
            this.refreshTeamInfo();
            updateAuthUser();
            this.setState({ modalView: null });

            mixpanel.track(EVENTS.JOIN_TEAM_SUCCESS);
        }).catch(error => {
            this.setState({ modalError: error.errorMsg });
        });
        event.preventDefault();
    }

    leaveTeam = (event, updateAuthUser) => {
        this.props.api.leaveTeam().then(() => {
            this.refreshTeamInfo();
            updateAuthUser();
            this.setState({ modalView: null });

            mixpanel.track(EVENTS.LEAVE_TEAM_SUCCESS);
        }).catch(error => {
            this.setState({ modalError: error.errorMsg });
        });
        event.preventDefault();
    }

    updateUser = (event, updateAuthUser) => {
        const { new_color, new_icon, new_country, new_tag } = this.state;
        // error check
        const toBeUpdated = {}
        if (new_color !== 0) { toBeUpdated.color = new_color };
        if (new_icon !== 0) { toBeUpdated.icon = new_icon };
        if (new_country !== '') { toBeUpdated.country = new_country };
        if (new_tag !== '') { toBeUpdated.tag = new_tag };
        this.props.api.updateUser(toBeUpdated).then(() => {
            this.setState({ modalView: null });
            this.refreshTeamInfo();
            updateAuthUser();
        }).catch(error => {
            this.setState({ modalError: error.errorMsg });
        });
        event.preventDefault();
    }

    submitTheme = (event) => {
        const { newTheme } = this.state;
        this.props.api.updateTeam({ theme: newTheme }).then(() => {
            this.refreshTeamInfo();
            this.setState({ isEditing: false });

            mixpanel.track(EVENTS.SELECT_THEME_SUCCESS, {
                'Theme': newTheme
            });
        }).catch(error => {
            this.setState({ themeError: error.errorMsg });
        });
        event.preventDefault();
    }

    onSubmitFinalSubmission = (event) => {
        if (event.response_id !== undefined && event.response_id !== "") {
            this.props.api.updateTeam({ submission_typeform_id: event.response_id }).then(() => {
                this.refreshTeamInfo();

                mixpanel.track(EVENTS.SUBMIT_BEGIN);
            }).catch(error => {
                console.log(error);
            });
        }
        return true;
    }


    render() {
        const { team, modalView, new_team_name, join_team_code, copied, isEditing, themeError, modalError, } = this.state;
        return (
            <AuthUserContext.Consumer>
                {({ authUser, updateAuthUser }) => (
                    <div>
                        {
                            (authUser.team) ?
                                // 1. Already in a team. 
                                (<>{team &&
                                    <>
                                        <div className="in-team-style">
                                            <Container className="gs-htg-block pb-4 d-flex justify-content-between">
                                                <div style={{ maxWidth: "100rem" }}>
                                                    <h1>Hey, Team <b>{team.team_name}</b>! <FontAwesomeIcon onClick={() => { this.setModalView(UPDATE_TEAM_NAME_MODAL) }} icon={faEdit} size="xs" /></h1>
                                                </div>
                                                {isDuringActivePeriod("TEAM_FORMATION_PERIOD", Date.now()) ?
                                                    <p className='pt-4 text-danger gs-clickable-text' onClick={() => { this.setModalView(LEAVE_TEAM_MODAL); mixpanel.track(EVENTS.LEAVE_TEAM_BEGIN) }}> Leave Team </p>
                                                    : <p className="pt-4 text-danger">Team formation period is now over</p>
                                                }
                                            </Container>

                                            <Container className="gs-in-team-block">
                                                <div className='d-inline-flex'>
                                                    <p><b>Your Unique Code:</b> {team.team_code}</p>
                                                    <Button
                                                        className="btn-purple-secondary mx-2 p-0"
                                                        onClick={() => { navigator.clipboard.writeText(team.team_code) }}
                                                        onFocus={() => { this.setState({ copied: true }) }}
                                                        onBlur={() => { this.setState({ copied: false }) }}
                                                        style={{ height: "23px", width: "111px", fontSize: "14px", borderRadius: "5px" }}>
                                                        {copied ? "Copied" : "Copy Code"}
                                                    </Button>
                                                </div>

                                                <div>
                                                    <b>Team Members:</b>
                                                    <Row>
                                                        {team.team_members.map((member, index) => (
                                                            <Col key={index} xl={3} lg={4} md={6} className="d-flex justify-content-center">
                                                                <MemberCard
                                                                    self={authUser.email === member.email}
                                                                    setModalView={this.setModalView}
                                                                    profile_color={member.color}
                                                                    profile_icon={member.icon}
                                                                    first_name={member.first_name}
                                                                    last_name={member.last_name}
                                                                    email={member.email}
                                                                    country={member.country}
                                                                    tag={member.tag} />
                                                            </Col>
                                                        ))}
                                                    </Row>
                                                </div>


                                                <div>
                                                    <p>
                                                        <b>Select Submission Theme: </b>
                                                        {/* Below line is used to show/hide the edit button logic since theme submission deadline has passed (8pm) */}
                                                        {true && (
                                                            <>
                                                                {isEditing ? (
                                                                    <>
                                                                        <span className='gs-clickable-text' onClick={this.submitTheme} style={{ color: "#A8CA71" }}> OK</span>
                                                                        {` `}
                                                                        <span className='gs-clickable-text' onClick={() => { this.setState({ isEditing: false }) /*; mixpanel.track(EVENTS.SELECT_THEME_CANCEL)*/ }}> Cancel </span>
                                                                        <br />
                                                                        <span style={{ fontSize: "14px" }}>Confirm your theme by March 14 11:59PM EST!</span>
                                                                        {themeError && <> <br /> <span className="text-danger"> {themeError} </span> </>}
                                                                    </>
                                                                ) : (
                                                                    <>
                                                                        {isDuringActivePeriod("THEME_SELECTION_PERIOD", Date.now()) ? (
                                                                            <span className='gs-clickable-text' onClick={() => { this.setState({ isEditing: true }); mixpanel.track(EVENTS.SELECT_THEME_BEGIN); }}> Edit </span>
                                                                        ) : (
                                                                            <>
                                                                                <span className='gs-clickable-text' onClick={() => { mixpanel.track(EVENTS.SELECT_THEME_BEGIN); }}> Edit </span>
                                                                                <p className="text-danger"> Theme selection period is now over!</p>
                                                                            </>
                                                                        )}
                                                                    </>
                                                                )}
                                                            </>
                                                        )}
                                                    </p>
                                                    <Row>
                                                        <Col xl={2} lg={3} md={4} className='mb-3 d-flex justify-content-center'>
                                                            <Button
                                                                className={`btn-teal${team.theme === SUSTAINABILITY && !isEditing ? '' : '-secondary'}`}
                                                                variant="secondary"
                                                                onClick={() => { this.setState({ newTheme: SUSTAINABILITY }) }}
                                                                disabled={!isEditing}
                                                                style={{ borderRadius: "14px", height: "48px", width: "150px", alignItems: "center", fontSize: "14px", lineHeight: "120%", display: "grid", gridTemplateColumns: "auto auto" }}
                                                            >
                                                                <Image src={PAGES_IMAGES.sustainable_icon.default} />Sustainable Infrastructure
                                                            </Button>
                                                        </Col>
                                                        <Col xl={2} lg={3} md={4} className='mb-3 d-flex justify-content-center'>
                                                            <Button
                                                                className={`btn-green${team.theme === ECONOMIC && !isEditing ? '' : '-secondary'}`}
                                                                variant="secondary"
                                                                onClick={() => { this.setState({ newTheme: ECONOMIC }) }}
                                                                disabled={!isEditing}
                                                                style={{ borderRadius: "14px", height: "48px", width: "150px", alignItems: "center", fontSize: "14px", lineHeight: "120%", display: "grid", gridTemplateColumns: "auto auto" }}
                                                            >
                                                                <Image src={PAGES_IMAGES.economic_icon.default} />Economic Empowerment
                                                            </Button>
                                                        </Col>
                                                        <Col xl={2} lg={3} md={4} className='mb-3 d-flex justify-content-center'>
                                                            <Button
                                                                onClick={() => { this.setState({ newTheme: HEALTH }) }}
                                                                variant={`${team.theme === HEALTH && !isEditing ? 'primary' : 'secondary'}`}
                                                                disabled={!isEditing}
                                                                style={{ borderRadius: "14px", height: "48px", width: "150px", alignItems: "center", fontSize: "14px", lineHeight: "120%", display: "grid", gridTemplateColumns: "auto auto" }}
                                                            >
                                                                <Image src={PAGES_IMAGES.health_icon.default} />Health &amp; Humanity
                                                            </Button>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </Container>

                                            <Container className="gs-in-team-block">
                                                <div>
                                                    <div className="gs-submission-block mb-3">
                                                        <h3>Submission Details</h3>
                                                        {/* Did not make this a CONST - will need to handle differently in future.*/}
                                                        <Button className="btn-purple m-0 p-0" style={{ width: "130px", height: "32px", borderRadius: "5px" }} onClick={() => window.open("https://forms.gle/RnhYd6my2xo749iNA", "_blank")}>
                                                            <FontAwesomeIcon icon={faCloudUploadAlt} />
                                                            {' '}Submit
                                                        </Button>
                                                        {/* <Button className="btn-purple m-0 p-0" style={{width: "130px", height: "32px", borderRadius: "5px"}} onClick={() => this.handleFinalSubmissionShow(team.team_code)}>
                                                    <FontAwesomeIcon icon={faCloudUploadAlt} /> 
                                                    {` `} {team.submission_typeform_id ? 'Resubmit' : 'Submit'}
                                                </Button> */}
                                                    </div>

                                                </div>
                                            </Container>
                                            <Image src={PAGES_IMAGES.in_team_bg.default} className='gs-in-team-bg-img' />
                                        </div>
                                    </>
                                }</>) :
                                // 2. No team yet
                                (
                                    <div style={{ paddingTop: "8rem" }}>
                                        <Container className="gs-htg-block pb-1">
                                            <div className="mr-auto ml-auto text-center" style={{ maxWidth: "20rem" }}>
                                                <h2 className="pb-3">You are currently not in a team</h2>
                                                {!isDuringActivePeriod("TEAM_FORMATION_PERIOD", Date.now()) &&
                                                    <p className="text-danger">The team formation period is now over <br /> You will be assigned to a team!</p>
                                                }
                                            </div>
                                        </Container>
                                        <Container className="gs-no-team-block">
                                            <Row className='d-flex justify-content-center'>
                                                <Col className='d-flex justify-content-center' lg={5}>
                                                    <Card className='p-2'>
                                                        <FontAwesomeIcon style={{ color: "#C4D0E2" }} icon={faDiceOne} size="2x" />
                                                        <Card.Body className='py-1'>
                                                            <div>
                                                                <h3><b> Create </b></h3>
                                                                <h5> New Team</h5>
                                                            </div>
                                                            <Image src={PAGES_IMAGES.pencil.default} />
                                                        </Card.Body>
                                                        {isDuringActivePeriod("TEAM_FORMATION_PERIOD", Date.now()) ?
                                                            <Button className="btn-green" onClick={() => { this.setModalView(CREATE_TEAM_MODAL); mixpanel.track(EVENTS.CREATE_TEAM_BEGIN) }} style={{ borderRadius: "18px", height: "40px", width: "170px", justifySelf: "center" }}> Create Now </Button>
                                                            : <Button className="btn-green" style={{ borderRadius: "18px", height: "40px", width: "170px", justifySelf: "center" }}> Create Now </Button>
                                                        }
                                                    </Card>
                                                </Col>
                                                <Col lg={1} className="d-flex align-items-center justify-content-center"> OR </Col>
                                                <Col lg={5} className='d-flex justify-content-center'>
                                                    <Card className='p-2'>
                                                        <FontAwesomeIcon style={{ color: "#C4D0E2" }} icon={faDiceTwo} size="2x" />
                                                        <Card.Body className='py-1'>
                                                            <div>
                                                                <h3><b> Join </b></h3>
                                                                <h5> Existing Team </h5>
                                                            </div>
                                                            <Image src={PAGES_IMAGES.join_team.default} />
                                                        </Card.Body>
                                                        {isDuringActivePeriod("TEAM_FORMATION_PERIOD", Date.now()) ?
                                                            <Button className="btn-yellow" onClick={() => { this.setModalView(JOIN_TEAM_MODAL); mixpanel.track(EVENTS.JOIN_TEAM_BEGIN) }} style={{ borderRadius: "18px", height: "40px", width: "170px", justifySelf: "center" }}> Enter Code </Button>
                                                            : <Button className="btn-yellow" style={{ borderRadius: "18px", height: "40px", width: "170px", justifySelf: "center" }}> Enter Code </Button>
                                                        }
                                                    </Card>
                                                </Col>
                                            </Row>
                                        </Container>
                                        <Image src={PAGES_IMAGES.no_team_bg.default} className='gs-no-team-bg-img' />
                                    </div>
                                )
                        }

                        {/* Create Team Modal */}
                        <Modal centered show={modalView === CREATE_TEAM_MODAL} onHide={() => { }}>
                            <Form onSubmit={(e) => { this.createNewTeam(e, updateAuthUser) }}>
                                <Modal.Header>
                                    <Modal.Title> <b> Create New Team </b> </Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <Form.Group>
                                        <Form.Label> Enter your team's name: </Form.Label>
                                        <Form.Control className='border pl-1' name="new_team_name" value={new_team_name} onChange={this.onChange} type="text" placeholder="Team 1" />
                                    </Form.Group>
                                    {modalError && <p className="text-danger">{modalError}</p>}
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button className="btn-secondary" onClick={() => { this.setState({ modalView: null }) /*; mixpanel.track(EVENTS.CREATE_TEAM_CANCEL)*/ }}>Cancel</Button>
                                    <Button className="btn-green" type="submit">Create</Button>
                                </Modal.Footer>
                            </Form>
                        </Modal>

                        {/* Join Team Modal */}
                        <Modal centered show={modalView === JOIN_TEAM_MODAL} onHide={() => { }}>
                            <Form onSubmit={(e) => { this.joinNewTeam(e, updateAuthUser) }}>
                                <Modal.Header>
                                    <Modal.Title> <b> Join Existing Team </b> </Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <Form.Group>
                                        <Form.Label> Enter your teams <b>unique code</b> to join: </Form.Label>
                                        <Form.Control className='border pl-1' name="join_team_code" value={join_team_code} onChange={this.onChange} type="text" placeholder="abc123" />
                                    </Form.Group>
                                    {modalError && <p className="text-danger">{modalError}</p>}
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button className="btn-secondary" onClick={() => { this.setState({ modalView: null }) /*; mixpanel.track(EVENTS.JOIN_TEAM_CANCEL)*/ }}>Cancel</Button>
                                    <Button className="btn-green" type="submit">Join</Button>
                                </Modal.Footer>
                            </Form>
                        </Modal>

                        {/* Leave Team Modal */}
                        <Modal centered show={modalView === LEAVE_TEAM_MODAL} onHide={() => { }}>
                            <Modal.Header>
                                <Modal.Title> <b> Leave Team </b> </Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                Are you sure you want to leave this team? <span className='text-red'> This action cannot be undone. </span>
                                {modalError && <p className="text-danger">{modalError}</p>}
                            </Modal.Body>
                            <Modal.Footer>
                                <Button className="btn-secondary" onClick={() => { this.setState({ modalView: null }) /*; mixpanel.track(EVENTS.LEAVE_TEAM_CANCEL)*/ }}>No</Button>
                                <Button className="btn-green" onClick={(e) => { this.leaveTeam(e, updateAuthUser) }}>Yes</Button>
                            </Modal.Footer>
                        </Modal>

                        {/* Update Team Name Modal */}
                        <Modal centered show={modalView === UPDATE_TEAM_NAME_MODAL} onHide={() => { }}>
                            <Form onSubmit={(e) => { this.updateTeamName(e, updateAuthUser) }}>
                                <Modal.Header>
                                    <Modal.Title> <b> Update Team Name </b> </Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <Form.Group>
                                        <Form.Label> Enter your new team name: </Form.Label>
                                        <Form.Control className='border pl-1' name="new_team_name" value={new_team_name} onChange={this.onChange} type="text" placeholder="Team 1" />
                                    </Form.Group>
                                    {modalError && <p className="text-danger">{modalError}</p>}
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button className="btn-secondary" onClick={() => { this.setState({ modalView: null }) }}>Cancel</Button>
                                    <Button className="btn-green" type="submit">Update</Button>
                                </Modal.Footer>
                            </Form>
                        </Modal>

                        {/* Edit Team Member Modal */}
                        <Modal centered show={modalView === EDIT_MEMBER_MODAL} onHide={() => { }}>
                            <Form onSubmit={(e) => { this.updateUser(e, updateAuthUser) }}>
                                <Modal.Header>
                                    <Modal.Title> <b> {authUser.first_name}{` `}{authUser.last_name} </b> </Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <Form.Group>
                                        <Form.Label> Profile Color </Form.Label>
                                        <Form.Control as="select" name="new_color" style={{ color: PROFILE_COLORS[this.state.new_color] }} defaultValue={authUser.color} onChange={this.onChange}>
                                            <option value={0} style={{ color: PROFILE_COLORS[0] }}>&#xf111;</option>
                                            <option value={1} style={{ color: PROFILE_COLORS[1] }}>&#xf111;</option>
                                            <option value={2} style={{ color: PROFILE_COLORS[2] }}>&#xf111;</option>
                                            <option value={3} style={{ color: PROFILE_COLORS[3] }}>&#xf111;</option>
                                            <option value={4} style={{ color: PROFILE_COLORS[4] }}>&#xf111;</option>
                                        </Form.Control>
                                    </Form.Group>
                                    {/* <Form.Group>
                                        <Form.Label> Profile Icon </Form.Label>
                                        <Form.Control as="select" name="new_icon" style={{color: PROFILE_COLORS[this.state.new_color]}} defaultValue={authUser.icon} onChange={this.onChange}>
                                            <option value={0} style={{color: PROFILE_COLORS[this.state.new_color]}}>&#xf007;</option>
                                            <option value={1} style={{color: PROFILE_COLORS[this.state.new_color]}}>&#xf25b;</option>
                                            <option value={2} style={{color: PROFILE_COLORS[this.state.new_color]}}>&#xf188;</option>
                                            <option value={3} style={{color: PROFILE_COLORS[this.state.new_color]}}>&#xf240;</option>
                                            <option value={4} style={{color: PROFILE_COLORS[this.state.new_color]}}>&#xf244;</option>
                                        </Form.Control>
                                    </Form.Group>
                                    <Form.Group>
                                        <Form.Label> Country </Form.Label>
                                        <Form.Control className='border pl-1' name="new_country" defaultValue={authUser.country} onChange={this.onChange} type="text" />
                                    </Form.Group>
                                    <Form.Group>
                                        <Form.Label> Tag </Form.Label>
                                        <Form.Control as="select" name="new_tag" defaultValue={authUser.tag} onChange={this.onChange}>
                                            <option>Frontend Developer</option>
                                            <option>Backend Developer</option>
                                            <option>UI/UX Designer</option>
                                            <option>Business</option>
                                            <option>Research</option>
                                        </Form.Control>
                                    </Form.Group> */}
                                    {modalError && <p className="text-danger">{modalError}</p>}
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button className="btn-secondary" onClick={() => { this.setState({ modalView: null }) }}>Cancel</Button>
                                    <Button className="btn-green" type="submit">Save</Button>
                                </Modal.Footer>
                            </Form>
                        </Modal>
                    </div>
                )}
            </AuthUserContext.Consumer>
        );
    }
}

// ATTENTION HERE - status must be ACCEPTED_RSVP_YES for teams page to be rendered.
const condition = authUser => !!authUser && ((authUser.acceptance_status === APPLICATION_STATUS.ACCEPTED_RSVP_YES && authUser.rsvp_option) || authUser.role === ROLES.ADMIN);

const Team = compose(
    withAPI,
    withAuthorization(condition),
)(TeamBase);

export default TeamPage;
