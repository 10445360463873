import { isDuringActivePeriod } from './isDuringActivePeriod';

export { isDuringActivePeriod };

export const ROUTES = {
    HOME: '/',
    LOGIN: '/login',
    REGISTER: '/register',
    FORGET_PASSWORD: '/forgotpassword',
    FORGET_PASSWORD_CONFIRM: '/forgotpasswordconfirm',
    RESET_PASSWORD: '/resetpassword/:key',
    RESET_PASSWORD_CONFIRM: '/resetpasswordconfirm',
    EMAIL_VERIFICATION: '/emailverification/:key',
    DASHBOARD: '/dashboard',
    DASHBOARD_HOME: '/dashboard/home',
    DASHBOARD_TEAM: '/dashboard/team',
    DASHBOARD_SCHEDULE: '/dashboard/schedule',
    DASHBOARD_WORKSHOP: '/dashboard/workshop',
    DASHBOARD_ACCOUNT: '/dashboard/account',
    DASHBOARD_RESOURCES: '/dashboard/resources',
    DASHBOARD_HACKERS: '/dashboard/hackers',
    ADMIN_REGISTER: '/admin/register',
    ADMIN_DASHBOARD_HOME: '/admin/dashboard/home',
    ADMIN_DASHBOARD_HACKER_INFO: '/admin/dashboard/hackerinfo',
    ADMIN_DASHBOARD_VIEW_HACKER: '/admin/dashboard/hackerinfo/:id',
};

export const BASE_URL = process.env.REACT_APP_BASE_URL;

/*
export const APPLICATION_TYPEFORM_URL = "https://form.typeform.com/to/MUnTSanO";

export const RSVP_TYPEFORM_URL = "https://form.typeform.com/to/B1gdqFJu";

export const FINAL_SUBMISSION_TYPEFORM_URL = "https://form.typeform.com/to/H5960fb6";

*/

export const MAILCHIMP_URL = "https://world.us20.list-manage.com/subscribe/post?u=3aaf1859239fe8e1d6390bbe6&id=66d8c3c157"

export const MAILCHIMP_CAMPAIGNS_URL = "https://us20.admin.mailchimp.com/campaigns/#t:campaigns-list";

/* Not used in 2022
export const TYPEFORM_ID = "x1ZNK4rt";

export const USERS_TABLE_NAME = "users";

export const EVENTS_TABLE_NAME = "events";
*/

export const PASSWORD_REGEX = /^(?=.*[A-Za-z])(?=.*\d)[a-zA-Z\d\w\W]{8,}$/;

export const timeRegex = /^(0?[1-9]|1[0-2]):[0-5][0-9]$/;

export const EXTERNAL_LINKS = {
    FACEBOOK: 'https://www.facebook.com/globalspark.world',
    INSTAGRAM: 'https://www.instagram.com/global.spark',
    LINKEDIN: 'https://www.linkedin.com/company/global-spark-world/',
    TWITTER: 'https://twitter.com/geweek?lang=en',
    EMAIL: 'contactus@globalspark.world',
    GS: 'https://globalspark.world',
};

export const SOCIAL_MEDIA_IMAGES = {
    htg_logo: require('../assets/images/logo/htg-logo.png'),
    logo: require('../assets/images/logo/bright-bg-long.png'),
    logo_short: require('../assets/images/logo/bright-bg-short.png'),
};

export const PAGES_IMAGES = {
    login: require('../assets/images/pages/login.svg'),
    password: require('../assets/images/pages/password.svg'),
    slack: require('../assets/images/pages/dashboard-home/slack.svg'),
    hopin: require('../assets/images/pages/dashboard-home/hopin.svg'),
    youre_in: require('../assets/images/pages/dashboard-home/youre-in.png'),
    schedule: require('../assets/images/pages/dashboard-home/schedule.svg'),
    hacker_guide: require('../assets/images/pages/dashboard-home/hacker-guide.svg'),
    climate: require('../assets/images/pages/dashboard-home/climate.svg'),
    economic: require('../assets/images/pages/dashboard-home/economic.svg'),
    equitable: require('../assets/images/pages/dashboard-home/equitable.svg'),
    global: require('../assets/images/pages/dashboard-home/global.svg'),
    business: require('../assets/images/pages/dashboard-home/business.svg'),
    technical: require('../assets/images/pages/dashboard-home/technical.svg'),
    no_team_bg: require('../assets/images/pages/dashboard-home/no-team-bg.svg'),
    in_team_bg: require('../assets/images/pages/dashboard-home/in-team-bg.svg'),
    banner1: require('../assets/images/pages/dashboard-home/banner1.svg'),
    pencil: require('../assets/images/pages/dashboard-home/pencil.svg'),
    join_team: require('../assets/images/pages/dashboard-home/join-team.svg'),
    sustainable_icon: require('../assets/images/pages/dashboard-home/sustainable-icon.svg'),
    economic_icon: require('../assets/images/pages/dashboard-home/economic-icon.svg'),
    health_icon: require('../assets/images/pages/dashboard-home/health-icon.svg'),
    hacker_default: require('../assets/images/pages/dashboard-home/hacker-default.png'),
    arrow_flat: require('../assets/images/pages/dashboard-home/arrow-flat.png'),
    resources_lightbulb: require('../assets/images/pages/dashboard-home/resources-lightbulb.png'),
    slack_png: require('../assets/images/pages/dashboard-home/slack-logo-icon.png')
};

export const SPONSOR_IMAGES = {
    agf: require('../assets/images/sponsors/agf.png'),
    bcgcfca: require('../assets/images/sponsors/bcgcfca.jpg'),
    civic: require('../assets/images/sponsors/civic.png'),
    ga: require('../assets/images/sponsors/ga.jpg'),
    rumie: require('../assets/images/sponsors/rumie.png'),
    soc: require('../assets/images/sponsors/soc.png'),
    stc: require('../assets/images/sponsors/stc.jpg'),
    wfp: require('../assets/images/sponsors/wfp.jpg'),
    wwf: require('../assets/images/sponsors/wwf.png'),
    gates: require('../assets/images/sponsors/gates.png'),
    gcc: require('../assets/images/sponsors/gcc.svg'),
    uncdf: require('../assets/images/sponsors/uncdf.png'),
    dsf: require('../assets/images/sponsors/dsf.png'),
    be: require('../assets/images/sponsors/be.png'),
    bcg: require('../assets/images/sponsors/bcg.png'),
    unicef: require('../assets/images/sponsors/unicef.png'),
    bi: require('../assets/images/sponsors/bi.png'),
}

export const HACKER_LINKS = {
    business: "https://docs.google.com/document/d/1xEGqfzt7vfHmCsBCDR5EeBfBGeVswCDmgvOAfwt-VrE/edit?usp=sharing",
    technical: "https://docs.google.com/document/d/1mvzaTNPpHktGaj-QEAV57nhPGseHgALblq3pzMRPLFs/edit?usp=sharing",
    hacker_guide: "https://guide.globalspark.world/",
    schedule: "https://htg22.notion.site/htg22/Hack-the-Globe-2022-Hacker-Guide-1d6b05bcdd08403cb03fda2114865b1e#14963206a6364b86af0fd6b70330ad95",
    slack: "https://join.slack.com/t/hacktheglobe2022/shared_invite/zt-13ydigxbm-6qiWWnM9F7qINhznBmM5XA",
    hopin: "https://hopin.com/events/hack-the-globe-2022-kickoff",
    economic_vid: "https://youtube.com/playlist?list=PLuCyvsObmnOG8RvYczlWi7EcYZlVbdLd1",
    economic_guide: "/files/economic-empowerment-theme-guide.pdf",
    climate_vid: "https://youtube.com/playlist?list=PLuCyvsObmnOE4LPvw2TfGFzAs0DfYYEm2",
    climate_guide: "/files/climate-change-theme-guide.pdf",
    society_vid: "https://youtube.com/playlist?list=PLuCyvsObmnOEWuWMguCToB21XUWlCr5lts",
    society_guide: "/files/equitable-societies-theme-guide.pdf",
    global_vid: "https://youtube.com/playlist?list=PLuCyvsObmnOH3REFG92UR6AE1WMxJHfn2",
    global_guide: "/files/global-health-theme-guide.pdf",
    office_hours: "https://docs.google.com/spreadsheets/d/1CFr4HMS-T0pAEwtQgWRA2KYq8FrTgvTyZQpfmfy_fjU/edit#gid=992115737",
    sustainable_guide: "/files/sustainable-societies-theme-guide.pdf",
    health_guide: "/files/health-humanity-theme-guide.pdf",
    economic_guide2022: "/files/economic-empowerment-theme-guide2022.pdf",
    hacker_guide_toronto2024: "https://docs.google.com/document/d/1mMfc3Enb9WsRh4Gn4U5LHimNB8Jhq0aXfCe0nB-CnNc/edit?usp=sharing",
    hacker_guide_london2024: "https://docs.google.com/document/d/1t0pSwpSkr-75WdTEPAn7jYPlpsorVTZcSK-vbKSOQvU/edit?usp=sharing",
    hacker_guide_onlinegmt2024: "https://docs.google.com/document/d/1nzwYSGkgClQk8wNTipTiy2YpmaN_N5QREjJzoG1mFak/edit?usp=sharing",
    hacker_guide_onlineest2024: "https://docs.google.com/document/d/1kC7ZW4onCrrD3VPcLXmCBZeEF7S7RL81QVO23_FJz_s/edit?usp=sharing",
    workshops_toronto2024: "https://docs.google.com/document/d/1gf5Ro3kYXkYv0unjyHJjs-uCwJo1cDMgXzAyn48oNj8/edit?usp=sharing",
    workshops_london2024: "https://docs.google.com/document/d/1YEy0cogXALWF7p7eSMc9zPT19Hqp4E4yoaLvPZyscl4/edit?usp=sharing",
    workshops_onlinegmt2024: "https://docs.google.com/document/d/1kFbcWIbK0t_lPbTBdwHsT-3-4Q2bbieZDsm5Woz6-Cw/edit?usp=sharing",
    workshops_onlineest2024: "https://docs.google.com/document/d/1RblaR5cLKBPCq4eQbDBtLsEliUjiPGNZ08KQctDL_WY/edit?usp=sharing",
    logistics_toronto2024: "https://docs.google.com/document/d/1-CSRyZTAdeSfVGxAtChQpDzve-AN3B3R7zQIEF6AXPo/edit?usp=sharing",
    logistics_london2024: "https://docs.google.com/document/d/1H0MjeoYO08RYK5BitvjrPop1Ds3j0G1pD8XHIEAxiQc/edit?usp=sharing",
    economic_guide2024: "https://docs.google.com/presentation/d/1eIlveVRQlC9TkjPyAkV9VtXkif3_752zYHMATDwmExY/edit?usp=sharing",
    health_guide2024: "https://docs.google.com/presentation/d/1hc6j6S0MvhQHcy4K8KMk2lD3LQcHLfpzyFQgnQEdMPA/edit?usp=sharing",
    sustainable_guide2024: "https://docs.google.com/presentation/d/14RpZU9jS6j31Q638Be79eEynF2I3BuM-knUrT82nxJA/edit?usp=sharing",
    join_slack_2024: "https://join.slack.com/t/hacktheglobe2024/shared_invite/zt-2c6o002zq-v0shsF_7tXhQ5HJCkGVeGg",
    htg_recordings_2024: "https://docs.google.com/document/d/1Rk3pRtxrMuWjqFc-GtrawUptLX2a16__seqXaI7xCVM/edit",
}

export const ROLES = {
    ADMIN: "Admin",
    USER: "Applicant"
};

export const APPLICATION_STATUS = {
    EMAILED_NOT_VERIFIED: "EMAILED_NOT_VERIFIED",
    NO_APP: "NO_APP",
    SUBMITTED: "SUBMITTED",
    ACCEPTED_NEED_RSVP: "ACCEPTED_NEED_RSVP",
    ACCEPTED_RSVP_YES: "ACCEPTED_RSVP_YES",
    ACCEPTED_RSVP_NO: "ACCEPTED_RSVP_NO",
    ACCEPTED_OFFER_EXPIRED: "ACCEPTED_OFFER_EXPIRED",
    WAITLISTED: "WAITLISTED",
    REJECTED: "REJECTED"
};
