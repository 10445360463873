import React, { Component } from 'react';
import { Button, Col, Container, Form, Image, Row } from 'react-bootstrap';
import { Link, withRouter } from 'react-router-dom';

import { PAGES_IMAGES, ROUTES, PASSWORD_REGEX } from '../../util';
import { withAPI } from '../../api';
import Header from '../../components/Header';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import mixpanel from 'mixpanel-browser';
import {EVENTS} from '../../events/event_constants';

const RegisterPage = () => (
    <div>
        <Container fluid>
            <Row className="h-100 mt-auto mb-auto">
                <Col md={5} xl={4}>
                    <Header />
                    <div className="gs-form-container pr-5 pl-5">
                        <RegisterForm />
                    </div>
                </Col>
                <Col md={7} xl={8} className="bg-light-yellow h-100 p-0 d-none d-md-block">
                    <Image src={PAGES_IMAGES.login.default} className="gs-login-image" />
                </Col>
            </Row>
        </Container>
    </div>
);

const INITIAL_STATE = {
    firstname: '',
    lastname: '',
    email: '',
    passwordOne: '',
    passwordTwo: '',
    error: null,
    submitted: false, 
};

class RegisterFormBase extends Component {
    constructor(props) {
        super(props);
        this.btnRef= React.createRef();
        this.state = { ...INITIAL_STATE };
    }

    onSubmit = event => {
        event.preventDefault();
        this.btnRef.current.setAttribute("disabled", "disabled");
        const { firstname, lastname, email, passwordOne, passwordTwo } = this.state;
    
        if (!PASSWORD_REGEX.test(passwordOne)) {
            this.btnRef.current.removeAttribute("disabled");
            this.setState({ error: "Password must have minimum 8 characters, at least 1 letter and 1 number." });
            return;
        }
        if (passwordOne !== passwordTwo) {
            this.btnRef.current.removeAttribute("disabled");
            this.setState({ error: "Non-matching passwords." });
            return;
        }

        
        this.props.api.register(email, passwordOne, firstname, lastname ).then(() => {
            mixpanel.track(EVENTS.REGISTER);
            this.setState({ ...INITIAL_STATE });
            this.setState({submitted: true});
        }).catch(error => {
            mixpanel.track(EVENTS.REGISTER, {
                'Reason': error.errorMsg
            })

            this.btnRef.current.removeAttribute("disabled");
            this.setState({ error: error.errorMsg });
        });

        /*this.props.firebase.doRegisterWithEmailAndPassword(email, passwordOne).then(authUser => {
            const userData = {
                first_name: firstname,
                last_name: lastname,
                email: email,
                acc_type: "hacker",
                acceptance_status: "no_app",
                rsvp_option: false,
                typeform_response_id: "",
            }
            return this.props.firebase.doCreateUserRecord(authUser.user.uid, userData);
        }).then(() => {
            return this.props.firebase.doSendEmailVerification();
        }).then(() => {
            this.setState({ ...INITIAL_STATE });
            this.props.history.push(ROUTES.DASHBOARD);
        }).catch(error => {
            if (error.code === ERROR_CODE_ACCOUNT_EXISTS) {
                error.message = ERROR_MSG_ACCOUNT_EXISTS;
            }
            this.refs.btn.removeAttribute("disabled");
            this.setState({ error: error.message });
        });*/
    };

    onChange = event => {
        this.setState({ [event.target.name]: event.target.value });
    };

    /* onChangeCheckbox = event => {
        this.setState({ [event.target.name]: event.target.checked });
    };
    */

    render() {
        const {firstname, lastname, email, passwordOne, passwordTwo, error } = this.state;
        const isInvalid = passwordOne === '' || passwordTwo === '' || email === '' || firstname === '' || lastname === '';
        return (
            this.state.submitted ?
            <>
                <h3 className="pb-3">Email Verification Link Sent!</h3>
                Please Check Your Email For Instructions
                <br />
                <Link to={ROUTES.LOGIN}>Back To Log In</Link>
            </>
            :
            <>
                <h3 className="pb-3">Register an account</h3>
                Already Have An Account? <Link to={ROUTES.LOGIN}>Log In</Link>
                <p></p>
                <p> NOTE: Please use the same name and email as you used to apply for Hack the Globe!</p>
                <Form onSubmit={this.onSubmit}>
                    <div className="pt-5 pb-5">
                    <Form.Group>
                        <Form.Control name="firstname" value={firstname} onChange={this.onChange} type="text" placeholder="First Name" />
                    </Form.Group>
                    <Form.Group>
                        <Form.Control name="lastname" value={lastname} onChange={this.onChange} type="text" placeholder="Last Name" />
                    </Form.Group>
                    <Form.Group>
                        <Form.Control name="email" value={email} onChange={this.onChange} type="email" placeholder="Email" />
                    </Form.Group>
                    <Form.Group>
                        <Form.Control name="passwordOne" value={passwordOne} onChange={this.onChange} type="password" placeholder="Password" />
                    </Form.Group>
                    <Form.Group>
                        <Form.Control name="passwordTwo" value={passwordTwo} onChange={this.onChange} type="password" placeholder="Confirm Password" />
                    </Form.Group>
                    <div className="pt-3 text-danger">
                        {error && <p>{error}</p>}
                    </div>
                    </div>
                    <Button ref={this.btnRef} className="mb-3" disabled={isInvalid} type="submit">
                        Let's Go <FontAwesomeIcon icon={faArrowRight}/>
                    </Button>
                </Form>
            </>
        );
    }
}

const RegisterForm = withRouter(withAPI(RegisterFormBase));

export default RegisterPage;
