import React from 'react';
import { Modal, ModalBody, ModalFooter, Button, Form, Row, Col, } from 'react-bootstrap';
import "./popup_style.css";
import { Component } from 'react';
import ModalHeader from 'react-bootstrap/esm/ModalHeader';
import { withAPI } from '../../../api';
import { PAGES_IMAGES} from '../../../util';
import { compose } from 'recompose';
const INITIAL_STATE = {
    checked: false,
    page: 1,
    first_name: '',
    last_name: '',
    email: '',
    timezone: '',
    location: '',
    linkedin: '',
    university: '',
    tag: '',
    acceptance_status: '',
    rsvp_option: '',
    food: '',
    error: null,
    should_render: false
};

class Popup extends Component{
    constructor(props) {
        super(props);
        this.state = { ...INITIAL_STATE };
        
    }

    async resetForm() {
        this.props.api.currentUser().then((data) => {
            this.setState(
                {
                    rsvp_option: data.User.rsvp_option, 
                    first_name: data.User.first_name,
                    last_name: data.User.last_name, 
                    email: data.User.email,
                    location: data.User.location, 
                    acceptance_status: data.User.acceptance_status,
                    timezone: data.User.timezone,
                    university: data.User.university,
                    linkedin: data.User.application_typeform_id,
                    tag: data.User.tag,
                    should_render: (!(data.User.rsvp_option) || data.User.acceptance_status === "ACCEPTED_NEED_RSVP") && data.User.acceptance_status !== "WAITLISTED"
                }
                );
        });
    };

    componentDidMount() {
        this.resetForm();
    };

    handleSubmission = () => {
        this.props.api.updateUser(
            {rsvp_option: true,
            first_name: this.state.first_name,
            last_name: this.state.last_name,
            application_typeform_id: this.state.linkedin,
            tag: this.state.tag,
            acceptance_status: "ACCEPTED_RSVP_YES",
            rsvp_typeform_id: this.state.food,
            }
        ).then(()=> {
            this.setState({...INITIAL_STATE});
            this.setState({page: null});
        }).catch(error => {
            console.log(error);
        });
    }

    handlecheck = (check) => {
        this.setState({check: !check});
    };

    onChange = event => {
        if (event.target.value === ''){
            this.setState({error: "Please complete all fields before continuing"});
        } else{
            this.setState({error: ''});
        }
        this.setState({ [event.target.name]: event.target.value });
    };

    render() {
        const {check, page, first_name, last_name, email, timezone, location, linkedin, university, tag, error, acceptance_status} = this.state;
        const isMissingInfo = !(first_name && last_name && university && tag && !error); 
        const should_render = this.state.should_render;
        if (acceptance_status === "WAITLISTED"){
            return(
                <Modal show centered size='xl' className='waitlisted'>
                    <ModalHeader className = 'welcome'>
                        Hi, {first_name}.
                    </ModalHeader>
                    <ModalBody>
                        <div className='popup-content-1'>
                           Thank you for creating your Hack the Globe 2024 account, and thank you for your application!
                           <br/>
                           <br/>
                           Unfortunately, at this time you are on the waitlist. 
                           <br/>
                           <br/>
                           Please keep an eye on your email as we will reach out as space becomes available.
                        </div>
                        
                    </ModalBody>
                    <img className="waitlist-img" src={PAGES_IMAGES.no_team_bg.default}></img>
                </Modal>

            )
        } else{
            return (
                // Only renders these modals if the user meets these conditions, thereby page can default to 1
                (should_render) &&
                <div>
                <Modal show={page === 1} centered size='lg' className='popup-content'>
        
                    <ModalHeader className = 'welcome'>
                        Welcome, {first_name} !
                    </ModalHeader>
                    <ModalBody>
                    <div className='popup-content-1'>
                        You're almost done creating your account!  We just need a few more details about you.
                        <br/><br/>
                        This is the Hacker Dashboard, where you can:
                    </div>
                    <div className='popup-content-2'>
                        <ul>
                            <li>RSVP to attend Hack the Globe 2024</li>
                            <li>View information about the hackathon</li>
                            <li>Access the profiles of other hackers</li>
                            <li>Find teammates and create a team</li>
                            <li>Choose a theme</li>
                            <li>Submit your final project</li>
                        </ul>
                    </div>
                    </ModalBody>
                    <ModalFooter>
                        <Button className="btn-purple" onClick={() => {this.setState({page: 2})}}>Next</Button>
                    </ModalFooter>
                
                </Modal>
                <Modal show = {page === 2} centered size='lg' className='popup-content'>
                    <ModalHeader className = 'welcome'>
                        RSVP now!
                    </ModalHeader>
                    <ModalBody>
                    <div className='popup-content-1'>
                        Please check the box below to acknowledge the following:
                        
                        <div className='terms-box'> 
                            <span>A reminder that by accepting your attendance at HTG’s {location} Hub, you agree that:</span>
                            
                            {(location === "Toronto") && <ul>
                                <li>You must attend all activities in-person at the Toronto Hub on March 2nd, March 16th, and March 17th</li>
                                <li>You bear all travel & accommodation costs associated with your stay in Toronto, ON</li>
                            </ul>}
                            {(location === "London") && <ul>
                                <li>You must attend all activities in-person at the London Hub on March 15th, March 16th, and March 17th</li>
                                <li>You bear all travel & accommodation costs associated with your stay in London, UK</li>
                            </ul>}
                            {(location.includes("Online")) && <ul>
                                <li>You must attend all activities online on March 16th and March 17th, and optionally on March 15th.</li>
                            </ul>}
                        </div>
                            
                            Please make sure that you are available before sending your RSVP as space is limited.
                        
                        <div >
                            <input checked={check} onChange={() => {this.handlecheck(check)}} type="checkbox"></input>
                            <span> I understand, and I would like to RSVP for the event.</span>
                        </div>
        
                    </div>
                    </ModalBody>
                    <ModalFooter>
                        <Button className="btn-purple-secondary mr-auto" onClick={() => {this.setState({page: 1})}}>Back</Button>
                        <Button className="btn-purple" disabled= {!check} onClick={() => {this.setState({page: 3})}}>Next</Button>
                    </ModalFooter>
                </Modal>
                
                <Modal show = {page === 3} centered size='lg' className='popup-content'>
                    <ModalHeader className = 'welcome'>
                        Confirm your existing information
                    </ModalHeader>
                    <ModalBody>
                        <div className='popup-content-1'>Please ensure that this information is correct.
                        If you wish to change any of the disabled fields, please contact hacktheglobe@globalspark.world.
                        </div>
                        <Form>
                        <div className="pt-5 pb-2">
                            <Form.Group as={Row}>
                                <Form.Label column sm="5">First Name</Form.Label>
                                <Col sm="7">
                                    <Form.Control className = 'form-input' name="first_name" value={first_name} onChange={this.onChange} type="text" placeholder="First Name" />
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row}>
                                <Form.Label column sm="5">Last Name</Form.Label>
                                <Col sm="7">
                                    <Form.Control className = 'form-input' name="last_name" value={last_name} onChange={this.onChange} type="text" placeholder="Last Name" />
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row}>
                                <Form.Label column sm="5">Email</Form.Label>
                                <Col sm="7">
                                    <Form.Control className = 'form-input' name="email" disabled value={email} type="text" placeholder="Email" />
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row}>
                                <Form.Label column sm="5">Hub</Form.Label>
                                <Col sm="7">
                                    <Form.Control className = 'form-input' name="location" value={location} disabled type="text" placeholder="Location" />
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row}>
                                <Form.Label column sm="5">Time Zone</Form.Label>
                                <Col sm="7">
                                    <Form.Control className = 'form-input' name="timezone" disabled value={timezone} type="text" placeholder="Time Zone" />
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row}>
                                <Form.Label column sm="5">School / Institution</Form.Label>
                                <Col sm="7">
                                    <Form.Control className = 'form-input' name="university" disabled value={university} type="text" placeholder="School / Institution" />
                                </Col>
                            </Form.Group>
                        </div>
                        </Form>
                        
                        {error && <p className="text-danger"> {error}</p>}
                        
                    </ModalBody>
                    <ModalFooter>
                        <Button className="btn-purple-secondary mr-auto" onClick={() => {this.setState({page: 2})}}>Back</Button>
                        <Button className="btn-purple" onClick={() => {this.setState({page: 4})}} disabled = {this.state.error}>Continue</Button>
                    </ModalFooter>
                </Modal>

                <Modal show = {page === 4} centered size='lg' className='popup-content'>
                    <ModalHeader className = 'welcome'>
                        Complete your Profile
                    </ModalHeader>
                    <ModalBody>
                        <div className='popup-content-1'> Complete the following fields with information that will be displayed to other hackers.
                        </div>
                        <Form>
                        <div className="pt-5 pb-2">

                            <Form.Group as={Row}>
                                {/* Called "Linkedin" for continuity purposes - can be any social media URL */}
                                <Form.Label column sm="5">Social URL</Form.Label>
                                <Col sm="7">
                                    <Form.Control className = 'form-input' name="linkedin" value = {linkedin} onChange={this.onChange} type="text" placeholder="Linkedin URL, etc." />
                                </Col>
                            </Form.Group> 
                            
                            <Form.Group as={Row}>
                                <Form.Label column sm="5">Desired Team Role</Form.Label>
                                <Col sm="7">
                                <Form.Control className = 'form-input' name = 'tag' value = {tag} as='select' onChange={this.onChange}>
                                    <option value = ''>-- Choose a team role --</option>
                                    <option>The Problem Detective</option>
                                    <option>The Entrepreneur</option>
                                    <option>The Financial Architect</option>
                                    <option>The Technical Alchemist</option>
                                
                                    
                                </Form.Control>
                                </Col>
                            </Form.Group>
                        
                        
                            {// Dietary Restrictions here
                            (location === "Toronto" || location === "London") && <Form.Group as={Row}>
                            
                                <Form.Label column sm="5">Any Dietary Restrictions?</Form.Label>
                                <Col sm="7">
                                    <Form.Control className = 'form-input' name="food" onChange={this.onChange} type="text" placeholder="Food Allergies, Dietary Restrictions, etc." />
                                </Col>
                            </Form.Group> }

                            {error && <p className="text-danger"> {error}</p>}
                            {(!error && isMissingInfo) && <p className="text-danger"> {"Please complete all fields before continuing"}</p>}
                        </div>
                        </Form>
                    </ModalBody>
                    <ModalFooter>
                        <Button className="btn-purple-secondary mr-auto" onClick={() => {this.setState({page: 3})}}>Back</Button>
                        <Button className="btn-purple" onClick={() => {this.setState({page: 5})}} disabled = {isMissingInfo}>Save</Button>
                    </ModalFooter>
                </Modal>
                
                <Modal show = {page === 5} centered size='lg' className='popup-content'>
                    <ModalHeader className = 'welcome'>
                        Next Steps:
                    </ModalHeader>
                    <ModalBody>
                        <div className='popup-content-1'>
                            Thank you for completing your profile!
                            <br/><br/>
                            You can return to edit these fields at any time through the 'Account' tab.
                            <br/> You can find teammates and view their profile information in the 'People' tab. 
                            <br/>You can create, join, and manage your team in the 'Teams' tab.
                        </div>
                        
                    </ModalBody>
                    <ModalFooter>
                        <Button className="btn-purple-secondary mr-auto" onClick={() => {this.setState({page: 4})}}>Back</Button>
                        <Button className="btn-purple" onClick={() => {this.handleSubmission()}}>Finish</Button>
                    </ModalFooter>
                </Modal>
                </div>
            )
        }
    }

}

const PopupRegistration= compose(
    withAPI,
)(Popup);

export default PopupRegistration;
