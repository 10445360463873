import React from 'react';
import { Navbar, Container, Image, Col, Row } from 'react-bootstrap';

import { EXTERNAL_LINKS, SOCIAL_MEDIA_IMAGES } from '../util';   

const Header = () => (
    <div>
        <Navbar className="gs-navbar">
            <Container fluid>
                <Col>
                    <a href={EXTERNAL_LINKS.GS} target="_blank" rel="noopener noreferrer">
                        <Row className="gs-navbar-logo">
                            <Image src={SOCIAL_MEDIA_IMAGES.logo_short} className="pr-3 pl-4 pt-4 pb-4 mt-auto mb-auto" fluid />
                            <h3 className="text-black mt-auto mb-auto"><b>Global</b> Spark</h3>
                        </Row>
                    </a>
                </Col>
            </Container>
        </Navbar>
    </div>
);

export default Header;
