import React from 'react';
import { compose } from 'recompose';
import { Button } from 'react-bootstrap';

import { withAPI } from '../../api';
import { withRouter } from 'react-router-dom';
import { ROUTES } from '../../util';

import mixpanel from 'mixpanel-browser';
import { EVENTS } from '../../events/event_constants';

const LogoutButtonBase = (props) => (
    <Button className="gs-text gs-link" onClick={() => {
        mixpanel.track(EVENTS.LOGOUT);
        props.api.logout().then(() => props.history.push(ROUTES.HOME));
    }}
    >
        Log out
    </Button>
);

const Logoutbutton = compose(
    withRouter,
    withAPI,
)(LogoutButtonBase);

export default Logoutbutton;
