import * as React from 'react';
// import { Button, Card, Col, Container, Image, Modal, Row, Accordion } from 'react-bootstrap';
import { Container } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faInstagram, faLinkedin } from '@fortawesome/free-brands-svg-icons';

import { EXTERNAL_LINKS } from '../util';

const Footer = () => {
    return (
        <div className="gs-footer">
            <Container>
                <div className="text-right pb-3">
                    <h5 className="text-black pb-2">
                        <b>Stay up to date with Global Spark!</b>
                    </h5>
                    <h4>
                        <a href={EXTERNAL_LINKS.FACEBOOK} className="text-black p-2" target="_blank" rel="noopener noreferrer">
                            <FontAwesomeIcon icon={faFacebook}/> 
                        </a>
                        <a href={EXTERNAL_LINKS.INSTAGRAM} className="text-black p-2" target="_blank" rel="noopener noreferrer">
                            <FontAwesomeIcon icon={faInstagram}/>
                        </a>
                        <a href={EXTERNAL_LINKS.LINKEDIN} className="text-black p-2" target="_blank" rel="noopener noreferrer">
                            <FontAwesomeIcon icon={faLinkedin}/>
                        </a>
                    </h4>
                </div>
            </Container>
        </div>
    );
};

export default Footer;
