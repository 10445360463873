import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Button, Col, Container, Form, Image, Row } from 'react-bootstrap';
import { PAGES_IMAGES, ROUTES, PASSWORD_REGEX } from '../../util';
import { compose } from 'recompose';
import Header from '../../components/Header';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { withAPI } from '../../api';

const ResetPasswordPage = () => (
    <div>
        <Container fluid>
            <Row className="h-100 mt-auto mb-auto">
                <Col md={5} xl={4}>
                    <Header />
                    <div className="gs-form-container pr-5 pl-5">
                        <h3 className="pb-3 pt-2">Reset Password</h3>
                        <ResetPasswordForm />
                    </div>
                </Col>
                <Col md={7} xl={8} className="bg-light-yellow h-100 p-0 d-none d-md-block">
                    <Image src={PAGES_IMAGES.password.default} className="gs-password-image" />
                </Col>
            </Row>
        </Container>
    </div>
);

class ResetPasswordBase extends Component {
    constructor(props) {
        super(props);
        this.btnRef= React.createRef();
        this.state = {
            new_password: '',
            error: null,
            reset_token: this.props.match.params.key
        };
    }

    onSubmit = event => {
        event.preventDefault();
        this.btnRef.current.setAttribute("disabled", "disabled");

        const { reset_token, new_password, } = this.state;
        if (!PASSWORD_REGEX.test(new_password)) {
            this.btnRef.current.removeAttribute("disabled");
            this.setState({ error: "Password must have minimum 8 characters, at least 1 letter and 1 number." });
            return;
        }
        this.props.api.resetPassword(new_password, reset_token).then(() => {
            this.setState({
                new_password: '', 
                error: null,
                reset_token: null
            });
            this.props.history.push(ROUTES.RESET_PASSWORD_CONFIRM);
        }).catch(error => {
            this.btnRef.current.removeAttribute("disabled");
            this.setState({ error: error.errorMsg });
        });

    }

    onChange = event => {
        this.setState({ [event.target.name]: event.target.value });
    }

    render() {
        const { new_password, error } = this.state;
        const isInvalid = new_password === '';
        return (
            <Form onSubmit={this.onSubmit}>
                <div className="pt-5 pb-5">
                    <Form.Group>
                        <Form.Control name="new_password" value={new_password} onChange={this.onChange} type="password" placeholder="Password" />
                    </Form.Group>
                </div>
                <Button ref={this.btnRef} className="mb-3" disabled={isInvalid} type="submit">
                    Let's Go <FontAwesomeIcon icon={faArrowRight} />
                </Button>
                {error && <p className='text-danger'>{error}</p>}
            </Form>
        )
    }
}

const ResetPasswordForm = compose(
    withRouter,
    withAPI
)(ResetPasswordBase);

export default ResetPasswordPage;