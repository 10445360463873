import React from 'react';
import { Container, Card, Accordion } from "react-bootstrap";

const FAQs = () => {
    return (
    <Container className="gs-htg-block">
        <div className="gs-home-faq-container">
        <h3>FAQs</h3>
        <Accordion className="bg-white">
        <Card>
            <Card.Header>
            <Accordion.Toggle as={"p"} eventKey="5">
            How do I contact the Hack the Globe team?
            </Accordion.Toggle>
            </Card.Header>
            <Accordion.Collapse eventKey="5">
            <Card.Body>
            The best way to reach us is via email: hacktheglobe@globalspark.world. You can also direct-message us on Slack.
            </Card.Body>
            </Accordion.Collapse>
        </Card>
        <Card>
            <Card.Header>
            <Accordion.Toggle as={"p"} eventKey="6">
            How do I create a team?
            </Accordion.Toggle>
            </Card.Header>
            <Accordion.Collapse eventKey="6">
            <Card.Body>
            Once you have found your team members, navigate to the ‘Team’ page and click Create Now. This will generate a unique Team Code which you should share with your prospective teammates.
            </Card.Body>
            </Accordion.Collapse>
        </Card>
        <Card>
            <Card.Header>
            <Accordion.Toggle as={"p"} eventKey="7">
            How do I join a team?
            </Accordion.Toggle>
            </Card.Header>
            <Accordion.Collapse eventKey="7">
            <Card.Body>
            You join a team in the ‘Team’ page. After clicking ‘Enter Code’, you enter the unique Team Code that your prospective teammates sent you. Don’t know what a Team Code is? See above for ‘How do I create a team’.
            </Card.Body>
            </Accordion.Collapse>
        </Card>
        <Card>
            <Card.Header>
            <Accordion.Toggle as={"p"} eventKey="8">
                How do I find prospective teammates?
            </Accordion.Toggle>
            </Card.Header>
            <Accordion.Collapse eventKey="8">
            <Card.Body>
            Navigate to the ‘Hackers’ page, and filter by the Hub you are in. Beside each Hacker’s name, you can see their team and their preferred team role. 
            If you need to contact them, expand their profiles to find their emails or contact them via Slack.
            </Card.Body>
            </Accordion.Collapse>
        </Card>
        <Card>
            <Card.Header>
            <Accordion.Toggle as={"p"} eventKey="0">
                What should I ask the theme experts during the theme Q &amp; A sessions?
            </Accordion.Toggle>
            </Card.Header>
            <Accordion.Collapse eventKey="0">
            <Card.Body>
            You can ask questions about: areas in their field that are in need of innovation, 
            challenges the NGOs are currently facing, obstacles to innovation in the non-profit 
            space, important factors to consider when building a social enterprise. Don't limit 
            yourself to these questions, however! 
            </Card.Body>
            </Accordion.Collapse>
        </Card>
        <Card>
            <Card.Header>
            <Accordion.Toggle as={"p"} eventKey="1">
            Are we assigned to a problem or do we choose the problem?
            </Accordion.Toggle>
            </Card.Header>
            <Accordion.Collapse eventKey="1">
            <Card.Body>
            It’s all up to your team to decide which of the themes and specific problems 
            you will address. The only constraint is that you address only one of the 
            themes.
            </Card.Body>
            </Accordion.Collapse>
        </Card>
        <Card>
            <Card.Header>
            <Accordion.Toggle as={"p"} eventKey="2">
            Does the technical solution have to be working code?
            </Accordion.Toggle>
            </Card.Header>
            <Accordion.Collapse eventKey="2">
            <Card.Body>
            We are aware that not all hackers have coding experience. You can start out with 
            wireframes, however we require that all teams submit a technical solution with a 
            software element (minimal viable product). Remember hackathons are all about 
            learning new skills and we will have workshops and technical mentors during the 
            event to help you achieve this!
            </Card.Body>
            </Accordion.Collapse>
        </Card>
        <Card>
            <Card.Header>
            <Accordion.Toggle as={"p"} eventKey="3">
            Is the technical solution only a software prototype? 
            </Accordion.Toggle>
            </Card.Header>
            <Accordion.Collapse eventKey="3">
            <Card.Body>
            For the purposes of the competition, focus on a software prototype. If your idea 
            requires hardware as well, you can explain this in the demo and include this in 
            your business plan.
            </Card.Body>
            </Accordion.Collapse>
        </Card>
        <Card>
            <Card.Header>
            <Accordion.Toggle as={"p"} eventKey="4">
            What is a social enterprise? 
            </Accordion.Toggle>
            </Card.Header>
            <Accordion.Collapse eventKey="4">
            <Card.Body>
            A social enterprise is an organization that seeks to maximize social impact by applying 
            commercial strategies. It can be for-profit or non-profit and focuses on having social 
            goals as well as financial goals. 
            </Card.Body>
            </Accordion.Collapse>
        </Card>
        </Accordion>

        </div>
    </Container>
    )
}

export default FAQs;
